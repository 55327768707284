<!--
 * @Author: ZS
 * @Date: 2022-11-16 09:38:09
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-18 17:08:28
 * @Description: 电源管理首页
-->
<template>
  <div id="powerManage">
    <div class="column column1">
      <div class="switchBox">
        <h4 class="tit">电源管理</h4>
        <img
          src="../../assets/images/power/powerClose.png"
          alt=""
          class="powerImg"
        />
        <img
          src="../../assets/images/power/powerOn.png"
          alt=""
          class="powerImg on"
          v-show="this.$store.state.powerIsOpen"
        />
        <div class="switchBtn open noselect" @click="open()">打开</div>
        <div class="switchBtn close noselect" @click="close()">关闭</div>
      </div>
    </div>
    <div class="column column2">
      <div class="row row1">
        <ul class="overViewNun">
          <li>
            <div>
              <h5>开关接入数量</h5>
              <p><b>{{this.$store.state.powerNoAll}}</b>台</p>
            </div>
          </li>
          <li>
            <div>
              <h5>当前已运行天数</h5>
              <p><b>0</b>天</p>
            </div>
          </li>
          <li>
            <div>
              <h5>实时负载</h5>
              <p><b>0</b>kw</p>
            </div>
          </li>
          <li>
            <div>
              <h5>累计用电量</h5>
              <p><b>0</b>kw</p>
            </div>
          </li>
          <li>
            <div>
              <h5>当月用电量</h5>
              <p><b>0</b>kw</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="row row2">
        <div class="more">
          <img src="../../assets/images/power/more.png" alt=""/>
        </div>

        <ul class="warningTab">
          <li
            :class="{ active: warningTabIndex == 0 }"
            @click="warningTabChange(0)"
          >
            当日报警
          </li>
          <li
            :class="{ active: warningTabIndex == 1 }"
            @click="warningTabChange(1)"
          >
            累计报警
          </li>
        </ul>
        <ul class="warningList" v-show="warningTabIndex == 0">
          <li>
            <div>
              <b>0</b>
              <p>漏电报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>2</b>
              <p>过流报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>1</b>
              <p>过压报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>6</b>
              <p>过载报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>7</b>
              <p>过温报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>3</b>
              <p>短路报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>0</b>
              <p>欠压报警</p>
            </div>
          </li>
        </ul>
        <ul class="warningList" v-show="warningTabIndex == 1">
          <li>
            <div>
              <b>1</b>
              <p>漏电报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>4</b>
              <p>过流报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>1</b>
              <p>过压报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>6</b>
              <p>过载报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>7</b>
              <p>过温报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>3</b>
              <p>短路报警</p>
            </div>
          </li>
          <li>
            <div>
              <b>0</b>
              <p>欠压报警</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="row row3">
        <div class="box1 box">
          <div class="boxTit">
            <span>累计用电量统计</span>
            <div class="searchCondition">
              <div class="radioGroup">
                <el-radio-group v-model="timeRadio" @change="radioChange">
                  <el-radio :label="'year'">年</el-radio>
                  <el-radio :label="'month'">月</el-radio>
                  <el-radio :label="'date'">日</el-radio>
                </el-radio-group>
              </div>
              <div class="timeGroup">
                <el-date-picker
                  v-model="timeVal"
                  :type="dateType"
                  placeholder="请选择"
                  :clearable="false"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="chartBox" id="line1"></div>
        </div>
        <div class="box2 box">
          <div class="boxTit">
            <span>每月用电量分布</span>
          </div>
          <div class="chartBox pieBox">
            <div class="totalNum">
              <p><b>{{totalPower}}</b>kw/h</p>
              <h5>累计用电量</h5>
            </div>
            <div id="pie1"></div>
            <div class="legend">
              <ul>
                <li class="le1" v-for="(item,index) in pieData1" :key="index" @mouseenter="legendEnter(index)" @mouseleave="legendLeave(index)">
                  <span><i :style="{ 'background-color': pieColor[index]}"></i>{{item.name}}</span> <span><b>{{item.value}}</b>KW/h</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row row4">
        <ul class="linkGroup">
          <li @click="linkTo('/power/batchM')">批量管理</li>
          <li @click="linkTo('/power/alarmStatis')">报警统计</li>
          <li @click="linkTo('/power/powerUseRank')">用电排行</li>
          <li @click="linkTo('/power/powerUseReport')">用电报告</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import { fetchPost, fetchGet, fetchDelete, hasAction } from "@/service/index";

export default {
  components: {
    countTo,
  },
  data() {
    return {
      isOpen: false,
      warningTabIndex: 0,
      timeRadio: "year",
      dateType: "year",
      timeVal: "",
      lineData1: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
      lineData2: [6, 7.2, 5.6, 8, 4, 7, 9, 4, 10, 11.4, 5, 8, 10],
      pie1:null,//饼图实例
      pieData1: [
        {
          value: "328",
          name: "1月",
        },
        {
          value: "456",
          name: "2月",
        },
        {
          value: "322",
          name: "3月",
        },
        {
          value: "400",
          name: "4月",
        },
        {
          value: "650",
          name: "5月",
        },
        {
          value: "326",
          name: "6月",
        },
        {
          value: "546",
          name: "7月",
        },
        {
          value: "200",
          name: "8月",
        },
        {
          value: "456",
          name: "9月",
        },
        {
          value: "322",
          name: "10月",
        },
        {
          value: "128",
          name: "11月",
        },
        {
          value: "128",
          name: "12月",
        },
      ],
      pieColor: [
        "#4684FD",
        "#46CBFD",
        "#32D0A3",
        "#0ABCCC",
        "#FFB31A",
        "#FF884D",
        "#FF6666",
        "#857AFF",
        "#CE80FF",
        "#FF70FA",
        "#7366FF",
        "#B8B8B8",
      ],
      totalPower:800
    };
  },
  mounted() {
    this.drawLine1();
    this.drawpie1();
  },
  methods: {
    open() {      
      if(this.$store.state.powerIsOpen){
        this.$message.error("请先关闭"); 
        return;
      }

      this.$store.commit("setPowerOpen", true); 

      var ids="";
     
      this.$store.state.leftMenu.forEach(e=>{
        e.children?.forEach(c=>{     
          ids=ids+","+c.clientId;
          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+c.roomId,deviceType:"power",deviceId:1,"status":1}).then((data) => {           
                if (data.status) {
                            
                } else {               
                  this.$message.error(data.msg);               
                }
            }).catch((err) => {
              console.log(err);
              this.$message.error(err);              
            });  
        });
      }); 

      this.$store.commit("setBatPowerOpen", {clientIds:ids,isOpen:true}); 
    },
    close() {
      if(!this.$store.state.powerIsOpen){
        this.$message.error("请先打开"); 
        return;
      }

      this.$store.commit("setPowerOpen", false); 

      var ids="";
     
      this.$store.state.leftMenu.forEach(e=>{
        e.children?.forEach(c=>{     
          ids=ids+","+c.clientId;
          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+c.roomId,deviceType:"power",deviceId:1,"status":0}).then((data) => {           
                if (data.status) {
                            
                } else {               
                  this.$message.error(data.msg);               
                }
            }).catch((err) => {
              console.log(err);
              this.$message.error(err);              
            });  
        });
      }); 

      this.$store.commit("setBatPowerOpen", {clientIds:ids,isOpen:false}); 
    },
    warningTabChange(idx) {
      this.warningTabIndex = idx;
    },
    radioChange(param) {
      // console.log(param)
      this.timeVal = "";
      this.dateType = param;
    },
    plgl() {
      this.$router.push("/power/batchM");
    },
    yjcx() {
      this.$router.push("/power/powerQ");
    },
    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      let line1 = this.$echarts.init(document.getElementById("line1"));
      let option = {
        title: {
          text: "累计用电量（kw）",
          textStyle: {
            color: "rgba(255,255,255,1)",
            fontWeight: "normal",
            fontSize: "14",
          },
          left: "15",
          top: "0",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#57617B",
            },
          },
          formatter: "{c}kw",
        },

        grid: {
          left: "30",
          right: "70",
          top: "30",
          bottom: "10",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666974",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#CED6E4",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              // 坐标轴刻度的相关设置
              show: false, // 不显示刻度
            },

            data: this.lineData1,
          },
        ],
        yAxis: [
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666974",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#CED6E4",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#CED6E4",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "用电量",
            type: "line",
            smooth: false,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 2,
                color: "#4684FD",
              },
            },

            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(79, 138, 253,.5)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(79, 138, 253,0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: "rgba(79, 138, 253,.9)",
                borderColor: "rgba(79, 138, 253,.3)",
                borderWidth: 4,
              },
            },
            data: this.lineData2,
            markLine: {
              symbol: ["none", "none"],
              data: [
                {
                  type: "average",
                  name: "平均值",
                  lineStyle: {
                    color: "#39DB98",
                  },
                  label: {
                    color: "#39DB98",
                    formatter: "{b}",
                  },
                },
                {
                  type: "max",
                  name: "用电峰值",
                  lineStyle: {
                    color: "#FF3737",
                  },
                  label: {
                    color: "#FF3737",
                    formatter: "{b}",
                  },
                },
                {
                  type: "min",
                  name: "用电谷值",
                  lineStyle: {
                    color: "#4684FD",
                  },
                  label: {
                    color: "#4684FD",
                    formatter: "{b}",
                  },
                },
              ],
            },
          },
        ],
      };
      line1.setOption(option);
      window.addEventListener("resize", function () {
        line1.resize();
      });
    },
    drawpie1() {
      this.pie1 = this.$echarts.init(document.getElementById("pie1"));
      let color = this.pieColor;
      let option = {
        color: color,
        tooltip: {
          trigger: "item",
          show: true,
        },
        series: [
          {
            name: "用电量（KW）",
            type: "pie",
            radius: ["80%", "95%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 2,
              borderColor: "#ffffff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.pieData1,
          },
        ],
      };
      this.pie1.setOption(option);
      window.addEventListener("resize", ()=> {
        this.pie1.resize();
      });
    },
    legendEnter(idx) {
      console.log(idx)
      this.pie1.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: idx
      })
    },
    legendLeave(idx) {
      console.log(idx + 'leave')
      this.pie1.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: idx
      })
    },
    linkTo(link) {
      this.$router.push(link);
    }
  },
};
</script>

<style scoped>
#powerManage {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px 0 25px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.column {
  height: 100%;
}

.column1 {
  width: calc((100% - 20px) * 0.25);
}

.column2 {
  width: calc((100% - 20px) * 0.75);
}

.column2 .row {
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.column2 .row1 {
  height: calc((100% - 54px) * 0.1816);
  margin-bottom: 14px;
}

.column2 .row2 {
  height: calc((100% - 54px) * 0.2363);
  background-color: #ffffff;
}

.column2 .row2 .more {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 38px;
  top: 18px;
  cursor: pointer;
  z-index: 9;
}

.column2 .row3 {
  height: calc((100% - 54px) * 0.4062);
  display: flex;
  /* justify-content: space-between; */
}

.column2 .row3 .box {
  background-color: #ffffff;
  border-radius: 10px;
  width: calc(50% - 10px);
  position: relative;
}

.column2 .row3 .box1 {
  margin-right: 20px;
}

.column2 .row4 {
  height: calc((100% - 54px) * 0.1757);
  background-color: #ffffff;
}

.overViewNun {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.overViewNun li {
  width: calc((100% - 40px) / 5);
  height: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}

.overViewNun li > div {
  color: #ffffff;
  position: absolute;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);
}

.overViewNun li > div h5 {
  font-size: 18px;
  margin-bottom: 5px;
}

.overViewNun li > div p {
  font-size: 18px;
}

.overViewNun li > div p b {
  font-size: 32px;
  padding-right: 10px;
}

.overViewNun li:first-child {
  background-image: url("../../assets/images/power/bg1.png");
}

.overViewNun li:nth-child(2) {
  background-image: url("../../assets/images/power/bg2.png");
}

.overViewNun li:nth-child(3) {
  background-image: url("../../assets/images/power/bg3.png");
}

.overViewNun li:nth-child(4) {
  background-image: url("../../assets/images/power/bg4.png");
}

.overViewNun li:nth-child(5) {
  background-image: url("../../assets/images/power/bg5.png");
}

.warningTab {
  width: 100%;
  position: relative;
  display: flex;
  height: 56px;
  line-height: 56px;
  padding-left: 10px;
}

.warningTab li {
  text-align: center;
  width: 130px;
  position: relative;
  font-size: 22px;
  color: rgba(27, 47, 97, 0.5);
  cursor: pointer;
}

.warningTab li.active {
  color: rgba(27, 47, 97, 1);
}

.warningTab li:nth-child(2)::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 19px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ced6e4;
}

.warningList {
  width: 100%;
  display: flex;
  height: calc(100% - 56px);
}

.warningList li {
  position: relative;
  width: calc((100% - 10px) / 7);
  height: 100%;
}

.warningList li > div {
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.warningList li > div b {
  color: #ff3636;
  font-size: 32px;
}

.warningList li > div p {
  text-align: center;
  color: #464852;
  font-size: 16px;
}

.boxTit {
  position: relative;
  height: 58px;
  display: flex;
  padding: 0 20px;
  width: 100%;
  justify-content: space-between;
}

.boxTit span {
  line-height: 58px;
  color: rgba(27, 47, 97, 1);
  font-size: 22px;
  padding-left: 10px;
}

.boxTit .searchCondition {
  display: flex;
  /* padding-top: 15px; */
}

.boxTit .searchCondition .radioGroup {
  margin-right: 20px;
  padding-top: 20px;
}

.boxTit .searchCondition .timeGroup {
  padding-top: 17px;
}

/* 单选框选中后的字体颜色 */
.boxTit /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #464852 !important;
}

/* 单选框选中后小圆点的颜色 */
.boxTit /deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #235cfb !important;
  border-color: #235cfb !important;
}

.boxTit /deep/ .el-radio__inner {
  width: 18px;
  height: 18px;
}

.boxTit /deep/ .el-radio__inner::after {
  width: 8px;
  height: 8px;
}

.boxTit /deep/ .el-radio__label {
  font-size: 16px;
  color: #464852;
  line-height: 20px;
}

.boxTit /deep/ .el-radio {
  margin-right: 10px;
}

.boxTit /deep/ .el-date-editor.el-input {
  width: 109px;
  height: 26px;
}

.boxTit /deep/ .el-input__inner {
  height: 26px;
  line-height: 26px;
  border-radius: 0;
  border: 1px solid #ced6e4;
  padding-right: 5px;
}

.boxTit /deep/ .el-input__icon {
  line-height: 26px;
  color: #5d85f3;
  font-size: 16px;
}

.chartBox {
  width: 100%;
  height: calc(100% - 58px);
  position: relative;
  /* background-color: pink; */
}
.chartBox.pieBox {
  padding-left: 220px;
}
.chartBox.pieBox .totalNum {
  position: absolute;
  width: 116px;
  height: 116px;
  border: 1px solid #199CFA;
  border-radius: 50%;
  left: 52px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding-top: 25px;
}
.chartBox.pieBox .totalNum p {
  font-size: 14px;
  color: #66686C;
  /* margin-bottom: 5px; */
}
.chartBox.pieBox .totalNum  b {
  font-size: 24px;
  color: #2B67FC;
}
.chartBox.pieBox .totalNum h5 {
  color: #66686C;
  font-size: 16px;
  font-weight: normal;
}
.legend ul {
    width: 100%;
    height: 195px;
    position: absolute;
    left: 20px;
    top: 54%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .legend ul li {
    height: 28px;
    line-height: 28px;
    width: 40%;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }
  .legend ul li span{
    position: relative;
    font-size: 14px;
    padding-left: 20px;
    color: #666974;

  }
  .legend ul li span i{
    position: absolute;
    display: inline-block;
    width: 9px;
    height: 9px;
    /* border-radius: 50%; */
    /* background-color: #00d8ff; */
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  .legend ul li span b {
    font-size: 14px;
    color: #2C97F1;
    font-weight: 400;
    padding-right: 2px;
  }
#pie1 {
  width: 220px;
  height: 170px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.legend {
    width: 100%;
    height: 100%;
    position: relative;
  }
.linkGroup {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.linkGroup li {
  width: 209px;
  height: 54px;
  border-radius: 27px;
  line-height: 54px;
  text-align: center;
  color: #ffffff;
  font-size: 22px;
  background: linear-gradient(90deg, #509cff 0%, #4896f7 100%);
  cursor: pointer;
}

.linkGroup li:hover {
  background: linear-gradient(90deg, #509cff 0%, #0e6bdd 100%);
}

.switchBox {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/power/powerBg.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}

.powerImg {
  width: 50%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  z-index: 1;
}

.powerImg.on {
  z-index: 2;
}

h4.tit {
  color: #ffffff;
  font-size: 22px;
  width: 100%;
  padding-left: 30px;
  height: 100px;
  line-height: 100px;
  font-weight: normal;
}

h5.tit {
  color: #1b2f61;
  font-size: 22px;
  line-height: 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px 0 30px;
  font-weight: normal;
}

h5.tit .iconfont {
  color: #98a6c6;
  cursor: pointer;
  font-size: 26px;
}

.switchBtn {
  width: 209px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.switchBtn.open {
  background: linear-gradient(0deg, #f7fcff 2%, #98e0f8 100%);
  color: #1e74a8;
  top: 43%;
}

.switchBtn.close {
  top: calc(43% + 76px);
  color: #ffffff;
  border: 1px solid #ffffff;
}

.cirBox {
  width: 20vw;
  height: 20vw;
  /* background-color: pink; */
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
}
</style>