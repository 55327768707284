<template>
  <div id="login">
    <el-form :model="form" status-icon :rules="rules" ref="form" class="login-form">
      <div class="loginTit">
        <span :class="{'active':tabIndex == 0}" @click="tabClick(0)">账号登录</span>
        <span :class="{'active':tabIndex == 1}" @click="tabClick(1)">手机号登录</span>
      </div>
      <div class="formbox unLogin" v-show="tabIndex == 0">
        <el-form-item prop="user_name" class="inputItem">
          <img src="../../assets/images/usernameIcon.png" alt="" class="tipImg">
          <div class="splitLine"></div>
          <el-input v-model="form.user_name" placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item prop="password" class="inputItem">
          <img src="../../assets/images/passwordIcon.png" alt="" class="tipImg">
          <div class="splitLine"></div>
          <el-input type="password" v-model="form.password" autocomplete="off" placeholder="请输入密码" v-if="!passwordShow">
          </el-input>
          <el-input type="text" v-model="form.password" autocomplete="off" placeholder="请输入密码" v-if="passwordShow">
          </el-input>
          <img src="../../assets/images/eyeClose.png" alt="" class="eyeIcon" @click="passwordShow = !passwordShow"
            v-if="!passwordShow">
          <img src="../../assets/images/eyeOpen.png" alt="" class="eyeIcon" @click="passwordShow = !passwordShow"
            v-if="passwordShow">
        </el-form-item>
        <el-form-item>
          <div class="m-unlogin">
            <div>
              <el-checkbox v-model="autoLogin">自动登录</el-checkbox>
            </div>
            <div>
              <a href="/#/enroll">忘记密码？</a>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="formbox phLogin" v-show="tabIndex == 1">
        <el-form-item prop="phoneNum" class="inputItem">
          <img src="../../assets/images/phoneIcon.png" alt="" class="tipImg">
          <div class="splitLine"></div>
          <el-input v-model="form.phoneNum" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item prop="phoneCode" class="phoneCodeItem">
          <el-input v-model="form.phoneCode" placeholder="请输入验证码" style="width:56%"></el-input>
          <div class="getCode noselect" @click="getVcode()">{{getCodeText}}</div>
        </el-form-item>
      </div>
      <!-- 账号密码登录 -->
      <el-button type="primary" @click="submitForm" class="loginBtn" v-show="tabIndex == 0">登录</el-button>
      <!-- 手机号码登录登录 -->
      <el-button type="primary" disabled @click="submitForm1" class="loginBtn" v-show="tabIndex == 1">登录</el-button>

      <div class="userAgreement">
        登录即视为同意<a href="javascript:;">《成才智造用户协议》</a>
      </div>
      <div class="login-links">       
        <a disable style="pointer-events:none;color: gray;">立即登录</a>
        <a href="/#/enroll">注册账号</a>
      </div>
    </el-form>
    <img src="../../assets/images/loginImg.png" alt="" class="loginImg">
  </div>
</template>

<script>
import { fetchPost, fetchGet } from "@/service/index";

  export default {
    data() {
      return {
        form: {
          user_name: "",
          password: "",
          phoneNum: "",
          phoneCode: ""
        },
        rules: {
          username: [{
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          }],
          password: [{
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }],
          phoneNum: [{
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          }],
          phoneCode: [{
            required: true,
            message: "请输入验证码",
            trigger: "blur"
          }]
        },
        tabIndex: 0,
        passwordShow: false, //是否展示输入密码
        autoLogin: false,
        codeBool: true,
        codeCount:0,
        getCodeText:'获取验证码'
      };
    },
    created(){
     var token= localStorage.getItem('token');
     if(token){
      window.sessionStorage.setItem("token", token); 
      this.$router.push("/");   
     }
    },
    methods: {
      submitForm() {
        fetchPost("/api/login", this.form).then((data) => {           
              if (data.status) {
                localStorage.setItem('user_name', data.name);
                localStorage.setItem('company', data.company);
                localStorage.setItem('company_id',data.company_id);                
                window.sessionStorage.setItem("token", data.token); 
                if(this.autoLogin){
                  localStorage.setItem('token', data.token)
                }else{
                  localStorage.removeItem('token');
                }                
                this.$router.push("/");                
              } else {               
                this.$message.error(data.msg);               
              }
        }).catch(() => {         
          this.$message.error("出错了");              
        });      
      },
      submitForm1() {
         localStorage.setItem('token', this.form.phoneCode)
         this.$router.push('/')
      },
      tabClick(idx) {
        this.tabIndex = idx
      },
      getVcode() {       
        var phoneReg = /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/;
        if (this.form.phoneNum.trim().length === 0) {
           this.$message.error('请输入手机号！');
          return false;
        }
        if (!phoneReg.test(this.form.phoneNum)) {
          this.$message.error('请输正确的手机号！');
          return false;
        }
        if (this.codeBool) {
          //这里发送验证码
          this.codeCount = 120
        }

        this.$message.error('短信服务已关闭');
        
        var timer = setInterval(() => {
          if (this.codeCount === 0) {
            clearInterval(timer);
            this.codeBool = true;
            this.getCodeText = '获取验证码'
          } else {
            this.getCodeText = this.codeCount + 'S后重新发送';
            this.codeCount--;
          }
        }, 1000);
      },
      enroll(){
        this.$router.push('/enroll')
      }
    },
  };
</script>
<style scoped>
  #login {
    background-color: #2F5FF2;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("../../assets/images/loginBg.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 20px 0 0 50px;
  }

  .loginImg {
    width: 55.1%;
    height: auto;
  }

  .login-form {
    width: 468px;
    height: 643px;
    background-color: #ffffff;
    padding: 65px 60px 0 60px;
    position: absolute;
    top: 50%;
    right: 8%;
    transform: translateY(-50%);
    z-index: 99;

  }

  .formbox {
    width: 100%;
    height: 195px;
  }

  .title-zc {
    text-align: center;
  }

  .loginBtn {
    width: 100%;
    height: 60px;
    background: linear-gradient(90deg, #28B1FF 0%, #2F5FF2 100%);
    border-radius: 2px;
    margin-top: 30px;
    margin: 20px 0 20px 0;
    font-size: 18px;
  }

  .inputItem {
    position: relative;
  }

  .inputItem .tipImg {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 23px;
    top: 50%;
    transform: translateY(-50%);
    color: #666666;
    z-index: 9;
  }

  .inputItem .eyeIcon {
    position: absolute;
    cursor: pointer;
    width: 24px;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9
  }

  .splitLine {
    position: absolute;
    content: "";
    width: 1px;
    height: 19px;
    background-color: #E3E5E9;
    left: 63px;
    top: 20px;
    z-index: 3;
  }

  .inputItem /deep/ .el-input,
  .phoneCodeItem /deep/ .el-input {
    font-size: 18px;
  }

  .inputItem /deep/ .el-input__inner {
    padding-left: 86px;
    border-radius: 2px;
    background-color: #F2F4F8;
    border: none;
    height: 59px;
    line-height: 59px;
    font-size: 18px;
    color: #999FA6;
  }

  .phoneCodeItem /deep/ .el-input__inner {
    padding-left: 36px;
    border-radius: 2px;
    background-color: #F2F4F8;
    border: none;
    height: 59px;
    line-height: 59px;
    font-size: 18px;
    color: #999FA6;
  }

  .m-unlogin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
  }

  .m-unlogin /deep/ .el-checkbox__label {
    font-size: 16px;
    color: #999FA6;
  }

  .m-unlogin a {
    font-size: 16px;
    color: #999FA6;
  }

  .m-unlogin a:hover {
    color: #2F5FF2;
  }

  .loginTit {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 35px;
  }

  .loginTit span {
    font-size: 22px;
    color: #787E86;
    line-height: 55px;
    height: 55px;
    cursor: pointer;
    position: relative;
  }

  .loginTit span.active::before {
    position: absolute;
    content: "";
    width: 33px;
    height: 4px;
    background: #3B86F7;
    border-radius: 2px;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);

  }

  .loginTit span.active {
    color: #020E1B;
  }

  .userAgreement {
    width: 100%;
    text-align: center;
  }

  .userAgreement a {
    color: #3B86F7;
  }

  .login-links {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 27px;
    left: 0px;
  }

  .login-links a {
    margin: 0 20px;
    font-size: 16px;
    color: #29343F;
    position: relative;
  }

  .login-links a:first-child::after {
    position: absolute;
    content: "";
    height: 19px;
    background-color: #E3E5E9;
    width: 1px;
    right: -20px;
    top: 2px;

  }

  .phoneCodeItem {
    /* display: flex;
    justify-content: space-between; */
  }

  .phoneCodeItem .getCode {
    height: 100%;
    width: 40%;
    background-color: #67C23A;
    float: right;
    height: 59px;
    line-height: 59px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 2px;

  }

  .phoneCodeItem .getCode:hover {
    background-color: #4dbe14;
  }
</style>