<template>
  <div id="container">    
    <el-card class="box-card">
    <div class="container">
      <div class="handle-box">
        <el-button
          type="danger"
          icon="el-icon-plus"
          class="handle-del mr10"
          @click="handleCreate(-1,{})"
        >新增单元</el-button>       
         
        <el-input v-model="query.name" placeholder="单元" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        :highlight-current-row="true"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>        
        <el-table-column prop="name" label="单元名称"></el-table-column>                
        <el-table-column prop="id" label="ID"  width="55" align="center"></el-table-column>         
        <el-table-column prop="building_no" label="楼栋" width="100" align="center"></el-table-column>
        <el-table-column prop="floor_no" label="楼层" width="100" align="center"></el-table-column>
        <el-table-column prop="light_no" label="灯光数量" width="100" align="center"></el-table-column>
        <el-table-column prop="disinfect_no" label="消毒数量" width="100" align="center"></el-table-column>
        <el-table-column prop="power_no" label="电源数量" width="100" align="center"></el-table-column>
        <el-table-column prop="ac_no" label="空调数量" width="100" align="center"></el-table-column>
        <el-table-column prop="curtain_no" label="窗帘数量" width="100" align="center"></el-table-column>
        <el-table-column prop="orders" label="排序" width="100" align="center"></el-table-column>
        <el-table-column label="录入时间" align="center"  width="160"  :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.created|dateformat('YYYY-MM-DD HH:mm:ss')}}</template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
            size="small"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>            
          </template>
        </el-table-column>
      </el-table>
    </div>
    </el-card>
    <!-- 编辑弹出框 -->
    <el-dialog :title="title" :visible.sync="editVisible" width="35%">
      <el-form ref="uform" :rules="rules" :model="uform" label-width="80px">        
        <el-form-item prop="id" label="编号" v-show="false">
          <el-input v-model="uform.id"></el-input>
        </el-form-item>        
        <el-form-item prop="superior_id" label="归属单元">
          <el-cascader
            size="small"
            :options="tableData"   
            :show-all-levels="true"
            :props="propsRegion"
            v-model="uform.superior_id"            
            placeholder="请选择归属单元"
            class="handle-del mr10"           
        ></el-cascader>
        </el-form-item>
        <el-form-item prop="name" label="单元名称">
          <el-input v-model="uform.name"></el-input>
        </el-form-item> 
        <el-form-item prop="building_no" label="楼栋号">
          <el-input v-model="uform.building_no"></el-input>
        </el-form-item>
        <el-form-item prop="floor_no" label="楼层号">
          <el-input v-model="uform.floor_no"></el-input>
        </el-form-item>   
        <el-form-item prop="light_no" label="灯光数量">
          <el-input v-model="uform.light_no"></el-input>
        </el-form-item>  
        <el-form-item prop="disinfect_no" label="消毒数量">
          <el-input v-model="uform.disinfect_no"></el-input>
        </el-form-item>  
        <el-form-item prop="power_no" label="电源数量">
          <el-input v-model="uform.power_no"></el-input>
        </el-form-item> 
        <el-form-item prop="ac_no" label="空调数量">
          <el-input v-model="uform.ac_no"></el-input>
        </el-form-item> 
        <el-form-item prop="curtain_no" label="窗帘数量">
          <el-input v-model="uform.curtain_no"></el-input>
        </el-form-item> 
        <el-form-item prop="icon" label="图标">
          <el-input v-model="uform.icon"></el-input>
        </el-form-item>      
        <el-form-item prop="orders" label="序号">
          <el-input v-model="uform.orders"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit('uform')">确 定</el-button>
      </span>
    </el-dialog>
 
  </div>
</template>
<script>
import { fetchPost, fetchGet, fetchDelete } from "@/service/index";


export default {
  data() {
    return {     
      query:{       
        name:"",
        pageIndex:1,
        pageSize:30
      },
      tableData: [],
      editVisible: false,      
      title: "",           
      companys:[],
      propsRegion:{
          value:"id",
          label:"name",
          children:"children",
          checkStrictly: true,
          expandTrigger:"click",
          emitPath:false, 
      },
      uform: {
        id: 0,
        name: "",
        company_id:0,
        superior_id: "",              
        building_no:"",
        floor_no:"",
        light_no:0,
        power_no:0,
        disinfect_no:0,
        ac_no:0,
        curtain:0,
        icon:"",
        orders: 1,       
      },
      rules: {
        name: [{ required: true, message: " ", trigger: "blur" }], 
        superior_id:[{ required: true, message: " ", trigger: "blur" }], 
      }
    };
  },
  created() {   
    this.getData();
  },
  methods: {
    getData() {
      fetchGet("/api/device/region/list", {  }).then((res) => {        
        if(res.status){
            this.tableData = res.data;             
        }
      });
    }, 
    
    handleSearch() {
      this.getData();
    },
    handleDelete(index, row) {
      if (row.is_menu) {
        this.$message.success("菜单项禁止删除！");
        return;
      }     
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          fetchGet("/api/device/region/del/" + row.id).then((res) => {
            if (res.status) {
              this.$message.success("删除成功");
              //this.tableData.splice(index, 1);
              this.getData();
            } else {
              this.$message.success(res.msg);
            }
          });
        })
        .catch(() => {
          console.log("出差了");
        });
    },
    

    handleCreate(index, row) {
      this.title = "新增功能";
      this.editVisible = true;
      this.uform = {
        id: 0,
        name: "",   
        company_id:this.query.company_id,    
        orders: 1,        
      };
    },
 
    handleEdit(index, row) {
      this.idx = index;      
      this.uform = row;
      this.editVisible = true;
      this.title = "编辑功能";

      console.log(row);
    },
    
    saveEdit(uform) {        
      this.$refs[uform].validate((valid) => {
        if (valid) {
          fetchPost("/api/device/region/edit", this.uform)
            .then((res) => {
              if (res.status) {
                this.editVisible = false;
                //this.$set(this.tableData, this.idx, this.uform);
                this.getData();
                this.refresh();
              }
              this.$message.success(res.msg);
            })
            .catch((err) => {
              this.$message.success(`出错了`);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    refresh(){
      fetchGet("/api/region/batch", {kind:1}).then((res) => {    
        if(res.status){          
          this.$store.commit("changeLightRoomList", res.data);          
        }else{
          this.$message.success(res.msg);
        }
      });

      fetchGet("/api/region/batch", {kind:2}).then((res) => {   
        if(res.status){            
          this.$store.commit("changeDisinfectRoomList", res.data);      
        }else{
          this.$message.success(res.msg);
        }
      });
    },   
  },
};
</script>

<style scoped>
#container{
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.box-card{
 padding-top: 15px;
}

.show-ellipsis {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.crumbs {
  padding-bottom: 5px;
}
.handle-box {
  margin-bottom: 15px;
  margin-top: -15px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
