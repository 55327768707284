<template>
   <div id="container">    
     <el-card class="box-card">
        <div class="container">
        <div class="handle-box">
            <el-button
            type="danger"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="handleCreate"
            >新增账号</el-button>       
            <el-select v-model="query.role_id" placeholder="角色" class="handle-select mr10">
            <el-option v-for="item in roles_top" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-input v-model="query.name" placeholder="用户名" class="handle-input mr10"></el-input>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            :highlight-current-row="true"
            :stripe="true"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
            <el-table-column prop="user_name" label="用户名" align="center"></el-table-column>   
            <el-table-column prop="name" label="姓名" align="center"></el-table-column>   
            <el-table-column prop="phone" label="电话" align="center"></el-table-column>         
            <el-table-column label="状态" align="center">
            <template slot-scope="scope">
                <el-tag
                :type="scope.row.status==1?'success':(scope.row.status==1?'danger':'')"
                >{{scope.row.status==1?"正常":"禁用"}}</el-tag>
            </template>
            </el-table-column>            
            <el-table-column prop="orders" label="排序" align="center" width="100"></el-table-column>
            <el-table-column label="注册时间" align="center" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{ scope.row.created|dateformat('YYYY-MM-DD HH:mm:ss')}}</template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="300">
            <template slot-scope="scope">
                <el-button
                size="small"
                type="primary"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button>
                <el-button
                size="small"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button>
                <el-button
                size="small"
                type="primary"
                icon="el-icon-refresh"
                @click="handlePassword(scope.$index, scope.row)"
                >重置</el-button>
            </template>
            </el-table-column>
        </el-table>
        <div class="pagination" style="padding-top:20px">
            <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.page_index"
            :page-size="query.page_size"
            :total="pageTotal"
            @current-change="handlePageChange"
            ></el-pagination>
        </div>
        </div>
     </el-card>

    <!-- 编辑弹出框 -->
    <el-dialog :title="title" :visible.sync="editVisible" width="30%">
      <el-form ref="uform" :rules="rules" :model="uform" label-width="70px">
       
        <el-form-item prop="role_id" label="角色">
          <el-select
            v-model="uform.role_id"
            placeholder="选择角色"
            class="handle-select mr10"
            @change="changeRole"
          >
            <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status" label="状态">
          <el-select v-model="uform.status" placeholder="选择状态" class="handle-select mr10">
            <el-option key="s0" label="正常" :value="1"></el-option>
            <el-option key="s1" label="停用" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="id" label="编号" v-show="false">
          <el-input v-model="uform.id"></el-input>
        </el-form-item>
        <el-form-item prop="user_name" label="账号" v-if="nameVisible">
          <el-input v-model="uform.user_name"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码" type="password" v-if="nameVisible">
          <el-input v-model="uform.password"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="姓名">
          <el-input v-model="uform.name"></el-input>
        </el-form-item> 
        <el-form-item prop="phone" label="电话">
          <el-input v-model="uform.phone"></el-input>
        </el-form-item>      
        <el-form-item prop="orders" label="序号">
          <el-input v-model="uform.orders"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit('uform')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchPost, fetchGet, fetchDelete, hasAction } from "@/service/index";
export default {
  name: "basetable",
  data() {
    return {
      query: {
        role_id: "",
        name: "",
        page_index: 1,
        page_size: 100,
      },
      roles_top: [],
      companys: [],
      roles: [],
      title: "编辑",
      nameVisible: false,
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      uform: {
        id: 0,
        role_id: "",
        user_name: "",
        phone: "",
        password: "",
        role_code: "",
        app_client:"",
        orders: 1,
        status: 1,
      },
      idx: -1,
      id: -1,
      rules: {
        id: [{type: "number",min: 0,  required: true,  message: "", trigger: "blur",}],
        role_id: [{ required: true, message: " ", trigger: "blur"}],
        user_name: [{ required: true, message: " ", trigger: "blur"}],
        password: [{ required: true, message: " ", trigger: "blur"}],
        name: [{ required: true, message: " ", trigger: "blur"}],
        phone: [{ required: true, message: " ", trigger: "blur"}],
        status: [{required: true,  message: " ",  trigger: "blur"}],
        orders: [{required: true, message: " ", trigger: "blur"}],
      },
    };
  },
  computed: {
    action_a: function () {
      return hasAction(4);
    },
  },
  created() {
    this.getData();
    this.getCompany();
    this.getRoles();
  },
  methods: {
    getCompany() {
      fetchGet("/api/company/option", this.query).then((res) => {
        this.companys = res.data;
      });
    },
    getRoles() {
      fetchGet("/api/base/role/list", { page_index: 1, page_size: 20 }).then(
        (res) => {          
          this.roles_top = res.data;
          this.roles=res.data;
        }
      );
    },
    getData() {
      fetchGet("/api/base/user/list", this.query).then((res) => {
        if(res.status){
          this.tableData = res.data;
          this.pageTotal = res.count;
        }else{
          this.$message.success(res.msg);
        }       
      });
    },
    handleSearch() {
      this.$set(this.query, 'page_index', 1);
      this.getData();
    },
    handleDelete(index, row) {
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(() => {
          fetchGet("/api/base/user/del/" + row.id).then((res) => {
            if (res.status) {
              this.$message.success("删除成功");
              this.tableData.splice(index, 1);
            } else {
              this.$message.success(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCreate() {
      this.title = "新增账号";
      this.editVisible = true;
      this.nameVisible = true;
      this.idVisible = false;
      this.uform = {
        id: 0,
        orders: 1,
        status: 1,
      };
    },
    handleEdit(index, row) {
      this.idx = index;
      this.uform = row;
      this.editVisible = true;
      this.title = "编辑账号";
      this.nameVisible = false;
      this.idVisible = true;     
    },
    changeCompany(item) {
      for (var i = 0; i < this.companys.length; i++) {
        if (this.companys[i].id == item) {
          this.uform.grade = this.companys[i].grade;
          this.uform.superior_id = this.companys[i].superior_id;
          this.uform.ancestor_id = this.companys[i].ancestor_id;
          this.uform.company = this.companys[i].name;
          break;
        }
      }      
    },
    changeRole(item) {
      for (var i = 0; i < this.roles.length; i++) {
        if (this.roles[i].id == item) {
          this.uform.role_code = this.roles[i].code;
          break;
        }
      }
    },
    saveEdit(uform) {
      this.$refs[uform].validate((valid) => {
        if (valid) {
          fetchPost("/api/base/user/edit", this.uform).then((res) => {
              if (res.status) {
                this.editVisible = false;
                this.$set(this.tableData, this.idx, this.uform);
                this.getData();
              }
              this.$message.success(res.msg);
            })
            .catch((err) => {
              this.$message.success(`出错了`);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handlePassword(index, row){
        this.$confirm("确定要重置吗？", "提示", {
        type: "warning",
      }).then(() => {
          fetchPost("/api/base/user/reset",{id: row.id}).then((res) => {
            if (res.status) {
              this.$message.success("重置成功");              
            } else {
              this.$message.success(res.msg);
            }
          });
        })
        .catch(() => {});
    },    
    handlePageChange(val) {
      this.$set(this.query, "page_index", val);
      this.getData();
    },
  },
};
</script>

<style scoped>
#container{
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.box-card{
 padding-top: 15px;
}
.crumbs {
  padding-bottom: 5px;
}
.handle-box {
  margin-bottom: 15px;
  margin-top: -15px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
