<!--
 * @Author: ZS
 * @Date: 2022-09-22 15:50:29
 * @LastEditors: ZS
 * @LastEditTime: 2022-09-27 16:34:34
 * @Description: 描述
-->
<template>
  <div class="leftMenu">
    <div class="menuList noselect" v-for="item in this.$store.state.leftMenu" :key="item.code">
      <div
        class="mainTit"
        :class="{ active: activeCode == item.code }"
        @click="menuClick(item)"
      >
        <i class="iconfont" :class="item.icon"></i>
        {{ item.name }}
        <div class="iconfont icon-gengduo"></div>
      </div>
      <div class="subTit noselect" :class="{ active: activeCode == item.code }">
        <p
          v-for="item1 in item.children"
          :key="item1.code"
          @click="subMenuClick(item1)"
          :class="{ active: activeCode1 == item1.code }"
        >
          {{ item1.name }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "path";
import { fetchPost, fetchGet, fetchDelete, hasAction } from "@/service/index";

export default {
  data() {
    return {     
      activeCode: "",
      activeCode1: "",
      pageVal: "",
    };
  },
  mounted() {   
    //console.log(this.$router.currentRoute.meta.pageVal)   
    if (this.pageVal == '') {
      this.pageVal = this.$router.currentRoute.meta.pageVal
    }
  },
  created() {
    this.getData();      
  },
  methods: {
    getData() {      
      fetchGet("/api/region/menu", {}).then((res) => {   
        if(res.status){              
          this.$store.commit("changeMenu", res.data);     
        }else{
          this.$message.success(res.msg);
        }       
      });
      
      fetchGet("/api/region/batch", {kind:1}).then((res) => {    
        if(res.status){          
          this.$store.commit("changeLightRoomList", res.data);          
        }else{
          this.$message.success(res.msg);
        }
      });

      fetchGet("/api/region/batch", {kind:2}).then((res) => {   
        if(res.status){            
          this.$store.commit("changeDisinfectRoomList", res.data);      
        }else{
          this.$message.success(res.msg);
        }
      });

      fetchGet("/api/region/batch", {kind:3}).then((res) => {   
        if(res.status){            
          this.$store.commit("changePowerRoomList", res.data);      
        }else{
          this.$message.success(res.msg);
        }
      });

      fetchGet("/api/region/batch", {kind:4}).then((res) => {   
        if(res.status){            
          this.$store.commit("changeACRoomList", res.data);            
        }else{
          this.$message.success(res.msg);
        }
      });

      fetchGet("/api/region/batch", {kind:5}).then((res) => {    
        if(res.status){          
          this.$store.commit("changeCurtainRoomList", res.data);          
        }else{
          this.$message.success(res.msg);
        }
      });

    },
    menuClick(params) {
      this.activeCode = params.code;
      this.$store.commit("changeActiveMenu", params);
      this.activeCode1 = "";
      if (this.pageVal.indexOf('light') > -1) {
        this.$router.push('/light/lightDept')
      } else if (this.pageVal.indexOf('curtain') > -1) {
        this.$router.push('/curtain/curtainDept')
      } else if (this.pageVal .indexOf('disinfect') > -1) {
        this.$router.push('/disinfect/disinfectDept')
      }else if(this.pageVal.indexOf('power') > -1) {
        this.$router.push('/power/powerDept')
      } else if(this.pageVal.indexOf('ac') > -1) {
        this.$router.push('/ac/acDept')
      }else {
        return;
      }
    },
    subMenuClick(params) {
      this.activeCode1 = params.code;
      this.$store.commit("changeActiveMenu", params);
      console.log(params)
      if (this.pageVal == "light1") {
        this.$router.push('/light/lightRoom')
      } else if (this.pageVal == "curtain1") {
        this.$router.push('/curtain/curtainRoom')
      } else if (this.pageVal == "disinfect1") {
        this.$router.push('/disinfect/disinfectRoom')
      } else if (this.pageVal == "power1") {
         this.$router.push('/power/powerRoom')
      }else if (this.pageVal == "ac1") {
         this.$router.push('/ac/acRoom')
      }
    },
  },
  watch: {
    $route(to, from) {    
      this.pageVal = to.meta.pageVal;
      if (to.meta.pageVal != 'light1' &&to.meta.pageVal != 'curtain1' && to.meta.pageVal != 'disinfect1'&& to.meta.pageVal != 'power1'&& to.meta.pageVal != 'ac1') {
         this.activeCode = "";
         this.activeCode1 = "";
      }
    },
  },
};
</script>
<style scoped>
.leftMenu {
  width: 100%;
  padding: 10px 17px 10px 10px;
}
.mainTit i.iconfont {
  background-color: #ffffff;
  color: #4d84ff;
  font-size: 16px;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  padding-left: 2px;
  margin-right: 10px;
}
.mainTit {
  font-size: 16px;
  color: #303c56;
  height: 45px;
  line-height: 45px;
  padding: 0 10px;
  cursor: pointer;
  margin: 4px 0 10px 0;
  position: relative;
}
.mainTit.active,
.mainTit:hover {
  background: linear-gradient(-90deg, #5392fe 2%, #245efb 100%);
  border-radius: 5px;
  color: #ffffff;
}
.mainTit div.iconfont {
  position: absolute;
  right: 24px;
  top: 0px;
  font-size: 14px;
  color: #8c9498;
}
.mainTit.active div.iconfont,
.mainTit:hover div.iconfont {
  color: #ffffff;
}
.mainTit.active .iconfont {
}
.subTit {
  /* height: 0px; */
  max-height: 0px;
  width: 100%;
  overflow: hidden;
  transition: all 0.4s;
}
.subTit.active {
  /* height: auto; */
  max-height: 500px;
}
.subTit p {
  height: 41px;
  line-height: 41px;
  color: #a1a3b2;
  padding-left: 50px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  margin: 6px 0;
}
.subTit p:hover,
.subTit p.active {
  background-color: #ffffff;
  color: #091233;
}
.subTit p::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #a1a3b2;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
</style>