<template>
   <div id="container">
      <div class="header">
        <Header></Header>
      </div>
      <div class="mainContent">
        <div class="left">
          <LeftBar></LeftBar>
        </div>
        <div class="right">
           <router-view />
        </div>
      </div>
    </div>
</template>

<script>
import { Header, LeftBar } from "../../components/index";
export default {
  name: "Layout",
  components: {
    Header,
    LeftBar,
  },
  data() {
    return {};
  },

  mounted() {},
  

  methods: {},
};
</script>

<style lang="css" scoped>
    #container {
  width: 100%;
  height: 100%;
}
#container .header {
  width: 100%;
  height: 215px;
}
#container .mainContent {
  width: 100%;
  height: calc(100% - 215px);
  background-color: #cccccc;
  display: flex;
}
 #container .mainContent {
 min-height: 720px;
 }
@media screen and (min-width: 1900px){
  #container .mainContent {
    min-height: 720px;
  }
}
#container .mainContent .left {
  width: 247px;
  height: 100%;
  overflow: auto;
  background-color: #EDF1F8;
}
#container .mainContent .right {
  width: calc(100% - 247px);
  height: 100%;
  background-color: #EDF1F8;
}
@media screen and (min-width: 1176px) and (max-width: 1400px){
    #container {
    transform: scale(0.7);
    transform-origin: 0 0;
    width: 142.85%;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1670px){
    #container {
    transform: scale(0.81);
    transform-origin: 0 0;
    width: 123.45%;
  }
}
</style>