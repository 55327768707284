<!--
 * @Author: ZS
 * @Date: 2022-11-16 10:52:53
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-18 17:06:07
 * @Description: 能效分析
-->
<template>
  <div id="powerData">
    <div class="row row1">
      <div class="boxTit">
        <span>月总用电量</span>
        <div class="searchCondition">
          <div class="timeGroup">
            <el-date-picker v-model="timeVal" :type="dateType" placeholder="请选择" :clearable="false">
            </el-date-picker>
          </div>

        </div>
      </div>
      <p class="unit">单位:KW/h</p>
      <div class="boxContent">
        <div class="numBox numBox1">
          <ul class="chartNum">
            <li v-for="(item,index) in totalNumList" :key="index" :class="{active:item.hasVal}">
              <countTo :startVal='0' :endVal='item.value' :duration='2000'></countTo>
            </li>
          </ul>
        </div>
        <div class="numBox numBox2">
          <ul class="numGather">
            <li>
              <p>4365</p>
              <h5>年总电量</h5>
            </li>
            <li>
              <p>656</p>
              <h5>上月总电量</h5>
            </li>
            <li class="up">
              <p>80.9%
                <img src="../../assets/images/power/up.png" alt="">
                <h5>月度环比</h5>
            </li>
            <!-- <li class="down">
              <p>80.9%
                <img src="../../assets/images/power/down.png" alt=""></p>
              <h5>月度环比</h5>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="row row2">
      <div class="column column1">
        <div class="boxTit">
          <span>年用电量趋势</span>
        </div>
        <div class="pieBox" id="bar1">
        </div>
      </div>
      <div class="column column2">
        <div class="boxTit">
          <span>月用电量趋势</span>
        </div>
        <div class="pieBox" id="line1">
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import countTo from "vue-count-to";
  export default {
    components: {
      countTo,
    },
    data() {
      return {
        dateType: "month",
        timeVal: '',
        totalNumList: null,
        totalNum: 2298, //须为整数
        bar1: null, //柱状图实例
        barData1: [{
            value: "328",
            name: "1月",
          },
          {
            value: "456",
            name: "2月",
          },
          {
            value: "322",
            name: "3月",
          },
          {
            value: "400",
            name: "4月",
          },
          {
            value: "650",
            name: "5月",
          },
          {
            value: "326",
            name: "6月",
          },
          {
            value: "546",
            name: "7月",
          },
          {
            value: "200",
            name: "8月",
          },
          {
            value: "456",
            name: "9月",
          },
          {
            value: "322",
            name: "10月",
          },
          {
            value: "128",
            name: "11月",
          },
          {
            value: "128",
            name: "12月",
          },
        ],
        lineData1: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
        ],
        lineData2: [6, 7.2, 5.6, 8, 4, 7, 9, 4, 10, 11.4, 5, 8, 10],
      };
    },
    mounted() {
      this.splitNumber()
      this.drawbar1()
      this.drawLine1()
    },
    methods: {
      // 分割数字
      splitNumber() {
        let temArr;
        let numArr = [];
        temArr = (this.totalNum + '').split('')
        for (let i = 0; i < temArr.length; i++) {
          numArr.push({
            value: temArr[i] - 0,
            hasVal: true
          })

        }
        let len = 7 - temArr.length;
        for (let j = 0; j < len; j++) {
          numArr.unshift({
            value: 0,
            hasVal: false
          })

        }
        this.totalNumList = numArr
        // console.log(numArr)
      },
      drawbar1() {
        this.bar1 = this.$echarts.init(document.getElementById("bar1"));
        let data1 = [],
          data2 = []
        for (let i = 0; i < this.barData1.length; i++) {
          data1.push(this.barData1[i].name);
          data2.push(this.barData1[i].value);
        }
        let option = {
          title: {
            text: "单位：w",
            show: false,
            textStyle: {
              color: '#9DA1B0',
              fontWeight: 'normal',
              fontSize: '13'
            },
            left: '10',
            top: '0'
          },
          grid: {
            top: "45",
            left: "35",
            right: "35",
            bottom: "25",
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            textStyle: {
              color: "#ffffff",
            },
            backgroundColor: "rgba(50,50,50,0.6)",
            transitionDuration: 0,
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          xAxis: [

            {
              type: "category",
              data: data1,
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#666974",
                },
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                // 坐标轴刻度的相关设置
                show: false, // 不显示刻度
              },
              axisLine: {
                lineStyle: {
                  color: "#CED6E4",
                },
              },
              splitLine: {
                show: false,
              },
            },

          ],
          yAxis: {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666974",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#CED6E4",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#CED6E4",
                type: "dashed",
              },
            },
          },
          series: [{
            data: data2,
            type: "bar",
            xAxisIndex: 0,
            name: "用电量",
            barMaxWidth: 14,
            itemStyle: {
              barBorderRadius: [11, 11, 11, 11], // 圆角（左上、右上、右下、左下）
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [{
                    offset: 0,
                    color: "#59ABF1",
                  },
                  {
                    offset: 1,
                    color: "#5D8DFF",
                  },
                ],
                false
              ),
            },
            // showBackground: true,
            // backgroundStyle: {
            //   color: "rgba(237, 241, 248,.5)",
            //   borderWidth:10
            // },
          }, ],
        };
        this.bar1.setOption(option);
        window.addEventListener("resize", function () {
          this.bar1.resize();
        });
      },
      drawLine1() {
        // 基于准备好的dom，初始化echarts实例
        let line1 = this.$echarts.init(document.getElementById("line1"));
        let option = {
          title: {
            text: "单位:KW/h",
            textStyle: {
              color: "#9DA1B0",
              fontWeight: "normal",
              fontSize: "14",
            },
            left: "15",
            top: "20",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              lineStyle: {
                color: "#57617B",
              },
            },
            formatter: "{c}kw",
          },

          grid: {
            left: "30",
            right: "30",
            top: "80",
            bottom: "30",
            containLabel: true,
          },
          xAxis: [{
            type: "category",
            boundaryGap: false,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666974",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#CED6E4",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              // 坐标轴刻度的相关设置
              show: false, // 不显示刻度
            },

            data: this.lineData1,
          }, ],
          yAxis: [{
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666974",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#CED6E4",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#CED6E4",
                type: "dashed",
              },
            },
          }, ],
          series: [{
            name: "用电量",
            type: "line",
            smooth: false,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 2,
                color: "#4684FD",
              },
            },

            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [{
                      offset: 0,
                      color: "rgba(79, 138, 253,.5)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(79, 138, 253,0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: "rgba(79, 138, 253,.9)",
                borderColor: "rgba(79, 138, 253,.3)",
                borderWidth: 4,
              },
            },
            data: this.lineData2,
          }, ],
        };
        line1.setOption(option);
        window.addEventListener("resize", function () {
          line1.resize();
        });
      },
    }
  }
</script>
<style scoped>
  #powerData {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 0 25px 0;
    height: 100%;
  }

  .row {
    width: 100%;
    position: relative;
  }

  .row1 {
    /* height: calc((100% - 20px) * 0.5323); */
    border-radius: 10px;
    height: 298px;
    margin-bottom: 20px;
    padding: 0 20px;
    background-color: #ffffff;
  }

  .row2 {
    /* height: calc((100% - 20px) * 0.4677); */
    height: calc(100% - 318px);
    width: 100%;
    display: flex;
  }

  .row2 .column {
    border-radius: 10px;
    width: 100%;
    position: relative;
    background-color: #ffffff;
    width: calc(50% - 10px);
  }

  .row2 .column1 {
    margin-right: 20px;
  }

  .boxTit {
    position: relative;
    height: 58px;
    display: flex;
    /* padding: 0 20px; */
    width: 100%;
    justify-content: space-between;
  }

  .boxTit span {
    line-height: 58px;
    color: rgba(27, 47, 97, 1);
    font-size: 22px;
    padding-left: 15px;
  }

  .boxTit .searchCondition {
    display: flex;
    /* padding-top: 15px; */
  }

  .boxTit /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 0;
    border: 1px solid #ced6e4;
    padding-right: 5px;
  }

  .boxTit /deep/ .el-input__icon {
    line-height: 32px;
    color: #5d85f3;
    font-size: 16px;
  }

  .boxTit .searchCondition .timeGroup {
    padding-top: 12px;
  }

  .boxTit /deep/ .el-date-editor.el-input {
    width: 179px;
    height: 32px;
  }

  .boxTit /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 0;
    border: 1px solid #ced6e4;
    padding-right: 5px;
  }

  p.unit {
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    padding-left: 15px;
  }

  .boxContent {
    width: 100%;
    height: calc(100% - 98px);
    /* background-color: pink; */
    display: flex;
  }

  .boxContent .numBox {
    height: 127px;
  }

  .boxContent .numBox1 {
    padding: 46px 100px 0 15px;
    border-right: 1px solid #eaeaea;
  }

  .boxContent .numBox2 {
    padding: 22px 0px 0 20px;
    width: calc(100% - 710px);
  }

  .chartNum {
    display: flex;
    width: 589px;
    justify-content: space-between;
  }

  .chartNum li {
    width: 65px;
    height: 77px;
    background-image: url("../../assets/images/power/numBg.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    border-radius: 10px;
    line-height: 77px;
    text-align: center;
    color: #A4B9ED;
    font-weight: 700;
    font-size: 40px;
  }

  li.active {
    color: #2B67FC;
  }

  .numGather {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .numGather li {
    text-align: center;
  }

  .numGather li h5 {
    width: 185px;
    height: 39px;
    background: #F2F5FA;
    border-radius: 2px;
    line-height: 39px;
    text-align: center;
    color: #464852;
    font-size: 20px;
    margin-top: 10px;
  }

  .numGather li p {
    color: #414654;
    font-weight: 700;
    font-size: 38px;
    /* display: flex; */
    width: 100%;
  }

  .numGather li.down p {
    color: #1BC179;
  }

  .numGather li.up p {
    color: #FC3E32;
  }

  .pieBox {
    width: 100%;
    height: calc(100% - 58px);
    /* background-color: pink; */
    position: relative;
  }
</style>