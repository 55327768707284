import service from './service';
import qs from 'qs'

export function fetchPost(url, params) {
    return new Promise((resolve, reject) => {
        service.post(url, qs.stringify(params))
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export async function fetchPostAsync(url, params) {
    var result = await service.post(url, qs.stringify(params));
    return result;
}

export function fetchGet(url, param) {
    return new Promise((resolve, reject) => {
        service.get(url, { params: param })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function fetchDelete(url) {
    return new Promise((resolve, reject) => {
        service.delete(url)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function fetchUpload(url, fromData) {
    return new Promise((resolve, reject) => {
        service.post(url, fromData)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function hasAction(id) {
    var actions = window.sessionStorage.getItem("actions");
    return actions.split(",").indexOf(id.toString()) != -1;
}




export default {
    fetchPost,
    fetchPostAsync,
    fetchGet,
    fetchDelete,
    fetchUpload,
    hasAction,
}