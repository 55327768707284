<template>
  <div id="disinfectQ">
    <ul>
      <li class="roomdisinfectBox green" v-for="(item,index) in this.$store.state.leftMenu" :key="index" :class="[{'green':item.status == 1},{'red':item.status == 0}]">
        <h5 class="tit">
          <i class="iconfont" :class="item.icon"></i>
          {{item.name}}消毒机数量
        </h5>
        <div class="nums">
          <div>
            <h6>单元</h6>
            <p><b>{{item.children?.length}}</b>室</p>
          </div>
          <div>
            <h6>消毒机</h6>
            <p><b>{{item.disinfectNo}}</b>套</p>
          </div>
        </div>
        <div class="status" v-if="item.status === 1">
          <i class="el-icon-success"></i>
          运行：正常
        </div>
        <div class="status" v-if="item.status === 0">
          <i class="el-icon-warning"></i>
          运行：异常
        </div>
      </li>
      <li class="roomdisinfectBox addMore" @click="addMore()">
        <img src="../../assets/images/addMore.png" alt="">
        <p>单元管理</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "disinfectQ",

  data() {
    return {
      disinfectRoomData:[{
        status:0,
        name:'办公室',
        num1:56,
        num2:100,
        icon:'icon-bangong'
      },{
        status:0,
        name:'教室',
        num1:56,
        num2:100,
        icon:'icon-jiaoshiguanli'
      },{
        status:0,
        name:'宿舍',
        num1:56,
        num2:100,
        icon:'icon-sushe'
      },{
        status:1,
        name:'食堂',
        num1:56,
        num2:100,
        icon:'icon-shitang'
      },{
        status:0,
        name:'会议室',
        num1:56,
        num2:100,
        icon:'icon-huiyishi1'
      },{
        status:0,
        name:'图书室',
        num1:6,
        num2:100,
        icon:'icon-tushuguan'
      },{
        status:1,
        name:'功能室',
        num1:12,
        num2:100,
        icon:'icon-gongneng'
      },{
        status:0,
        name:'校园操场',
        num1:56,
        num2:100,
        icon:'icon-changguanyuyue'
      },{
        status:1,
        name:'校园绿化',
        num1:56,
        num2:100,
        icon:'icon-GIS-tulitucengicon_lvhuabiao'
      },{
        status:0,
        name:'停车场',
        num1:56,
        num2:100,
        icon:'icon-tingchechang'
      },{
        status:0,
        name:'安防',
        num1:56,
        num2:100,
        icon:'icon-anquan'
      }]
    };
  },

  mounted() {},

  methods: {
    addMore() {
      this.$router.push('/setting/region');
    }
  },
};
</script>

<style lang="css" scoped>
#disinfectQ {
  width: 100%;
  /* height: 100%; */
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 20px;
  padding: 20px 0 25px 0;
  height: 100%;
  /* background-color: papayawhip; */
  overflow: auto;
}
#disinfectQ > ul {
  display: flex;
  flex-wrap: wrap;
}
#disinfectQ ul .roomdisinfectBox {
  /* width: 246px; */
  /* width: calc((100% - 140px) / 6); */
  height: 221px;
  background-color: #ffffff;
  margin-bottom: 28px;
  border-radius: 20px;
  margin-right: 28px;
}
#disinfectQ ul .roomdisinfectBox:nth-child(6n) {
  margin-right: 0;
}
@media screen and (max-width: 1900px) {
  #disinfectQ ul .roomdisinfectBox {
    width: calc((100% - 112px) / 5);
  }
  #disinfectQ ul .roomdisinfectBox:nth-child(5n) {
    margin-right: 0;
  }
  #disinfectQ ul .roomdisinfectBox:nth-child(6n) {
    margin-right: 28px;
  }
}
@media screen and (min-width: 1910px) {
  #disinfectQ ul .roomdisinfectBox {
    width: calc((100% - 140px) / 6);
  }
  #disinfectQ ul .roomdisinfectBox:nth-child(5n) {
    margin-right: 28;
  }
  #disinfectQ ul .roomdisinfectBox:nth-child(6n) {
    margin-right: 0px;
  }
}
.roomdisinfectBox {
  padding: 17px 17px;
}
.roomdisinfectBox.green h5.tit .iconfont {
  background: #4685FD;
}
.roomdisinfectBox.red  h5.tit .iconfont {
  background: #FF3F3F;
}
.roomdisinfectBox h5.tit {
  font-size: 21px;
  height: 34px;
  line-height: 34px;
  
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.roomdisinfectBox h5.tit .iconfont {
  display: inline-block;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 18px;
background: #4685FD;
text-align: center;
/* padding-left: 4px; */
line-height: 31px;
vertical-align: bottom;
margin-right: 4px;
}
.roomdisinfectBox h5.tit .iconfont.icon-bangong {
  padding-left: 4px;
}
.roomdisinfectBox .nums {
  display: flex;
  justify-content: space-between;
  padding: 25px 10px 25px 10px;
}
.roomdisinfectBox .nums h6 {
  color: #757781;
  font-size: 16px;
  font-weight: normal;
}
.roomdisinfectBox .nums p {
  font-size: 16px;
  color: #757781;
}
.roomdisinfectBox .nums p b {
  font-size: 32px;
  color: #464852;
  padding-right: 6px;
  font-weight: normal;
}
.roomdisinfectBox .status {
  width: 126px;
height: 27px;
line-height: 26px;
background: rgba(4,184,110,0);

border-radius: 14px;
text-align: center;
}
.roomdisinfectBox.green .status {
  color: #03B86D;
  border: 1px solid #03B86D;
}
.roomdisinfectBox.red .status {
  color: #FF3F3F;
  border: 1px solid #FF3F3F;
}
.addMore {
  text-align: center;
}
.addMore img{
  margin-top: 56px;
  margin: 28px 0 39px 0;
  cursor: pointer;
}
.addMore p {
font-size: 16px;
color: #757781;
}
</style>