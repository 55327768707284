<!--
 * @Author: ZS
 * @Date: 2022-11-16 11:00:49
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-18 11:38:21
 * @Description: 电源管理一级 二级（点击左侧菜单）
-->
<template>
  <div id="powerManage">
    <div class="column column1">
      <div class="switchBox">
        <h4 class="tit">电源管理</h4>
        <img src="../../assets/images/power/powerClose1.png" alt="" class="powerImg" />
        <img src="../../assets/images/power/powerOn1.png" alt="" class="powerImg on" v-show="this.$store.state.powerIsOpen" />
        <div class="switchBtn open noselect" @click="open()">打开</div>
        <div class="switchBtn close noselect" @click="close()">关闭</div>
      </div>
    </div>
    <div class="column column2">
      <div class="row row1">
        <h5 class="tit">
          <span>累计用电量统计</span><i class="iconfont icon-more1"></i>
        </h5>

        <div class="pieBox">
          <div class="warningBox">
            <div class="warningBoxIcon">
              <img src="../../assets/images/power/bell.png" alt="">
            </div>
            <span>预警报警</span>
            <b @click="viewWarning()">{{warningNum}}</b>
          </div>
          <div class="cirBox">
            <img src="../../assets/images/power/cir5-2.png" alt="" class="cir1-1" style="width:361px;height:356px" />
            <img src="../../assets/images/power/cir5-4.png" alt="" class="cir1-3" style="width:275px;height:275px" />
            <img src="../../assets/images/power/cir5-3.png" alt="" class="cir1-2" style="width:267px;height:267px" />
            <img src="../../assets/images/power/cir5-1.png" alt="" class="cir1-1" style="width:361px;height:356px" />
            <div class="numBox" style="z-index: 4;">
              <div class="countNum">
                <countTo :startVal='0' :endVal='num1' :duration='1000'></countTo>
              </div>

              <h5>当前功率</h5>
              <p>月电量<b>22</b>KW</p>
            </div>
            <!-- <div class="numBg"></div> -->
          </div>
          <ul class="detailBox">
            <li>
              <p><b>2</b>A</p>
              <h5>电流</h5>
            </li>
            <li>
              <p><b>70</b>mA</p>
              <h5>漏电</h5>
            </li>
            <li>
              <p><b>220.0</b>v</p>
              <h5>电压</h5>
            </li>
            <li>
              <p><b>32</b>°C</p>
              <h5>温度</h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="column column3">
      <div class="row row1">
        <h5 class="tit">
          <span>近12小时电量趋势</span><i class="iconfont icon-more1"></i>
        </h5>
        <div class="pieBox" id="line1">

        </div>
      </div>
      <div class="row row2">
        <ul class="linkGroup">

          <li @click="linkTo('/power/powerData')"><span>电量数据</span><img src="../../assets/images/power/icon1.png"
              alt=""></li>
          <li @click="linkTo('/power/efficiencyAnalysis')"><span>能效分析</span><img
              src="../../assets/images/power/icon2.png" alt=""></li>
          <li @click="linkTo('/power/repairManagement')"><span>报修管理</span><img src="../../assets/images/power/icon3.png"
              alt=""></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import countTo from 'vue-count-to';
  import { fetchPost, fetchGet, fetchDelete, hasAction } from "@/service/index";

  export default {
    components: {
      countTo
    },
    data() {
      return {
        companyId: localStorage.getItem('company_id'),        
        num1: 437,
        warningNum: 0,
        lineData1: [
          "1:00",
          "2:00",
          "3:00",
          "4:00",
          "5:00",
          "6:00",
          "7:00",
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
        ],
        lineData2: [6, 7.2, 5.6, 8, 4, 7, 9, 4, 10, 11.4, 5, 8, 10],
      };
    },
    mounted() {
      this.drawLine1()
    },
    methods: {
      open() {
        if(this.$store.state.powerIsOpen){
            this.$message.error("请先关闭"); 
            return;
          }

          this.$store.commit("setPowerOpen", true);         

          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+this.$store.state.leftActiveMenu.roomId,deviceType:"power",deviceId:1,"status":1}).then((data) => {           
              if (data.status) {
                          
              } else {               
                this.$message.error(data.msg);               
              }
          }).catch((err) => {
            console.log(err);
            this.$message.error(err);              
          });  
      },
      close() {
        if(!this.$store.state.powerIsOpen){
            this.$message.error("请先打开"); 
            return;
          }

          this.$store.commit("setPowerOpen", false);           
          
          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+this.$store.state.leftActiveMenu.roomId,deviceType:"power",deviceId:1,"status":0}).then((data) => {           
              if (data.status) {
                          
              } else {               
                this.$message.error(data.msg);               
              }
          }).catch((err) => {
            console.log(err);
            this.$message.error(err);              
          }); 
      },
      drawLine1() {
        // 基于准备好的dom，初始化echarts实例
        let line1 = this.$echarts.init(document.getElementById("line1"));
        let option = {
          title: {
            text: "单位:KW/h",
            textStyle: {
              color: "#9DA1B0",
              fontWeight: "normal",
              fontSize: "14",
            },
            left: "15",
            top: "20",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              lineStyle: {
                color: "#57617B",
              },
            },
            formatter: "{c}kw",
          },

          grid: {
            left: "30",
            right: "30",
            top: "80",
            bottom: "30",
            containLabel: true,
          },
          xAxis: [{
            type: "category",
            boundaryGap: false,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666974",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#CED6E4",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              // 坐标轴刻度的相关设置
              show: false, // 不显示刻度
            },

            data: this.lineData1,
          }, ],
          yAxis: [{
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666974",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#CED6E4",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#CED6E4",
                type: "dashed",
              },
            },
          }, ],
          series: [{
            name: "用电量",
            type: "line",
            smooth: false,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 2,
                color: "#4684FD",
              },
            },

            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [{
                      offset: 0,
                      color: "rgba(79, 138, 253,.5)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(79, 138, 253,0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: "rgba(79, 138, 253,.9)",
                borderColor: "rgba(79, 138, 253,.3)",
                borderWidth: 4,
              },
            },
            data: this.lineData2,
          }, ],
        };
        line1.setOption(option);
        window.addEventListener("resize", function () {
          line1.resize();
        });
      },
      linkTo(link) {
        this.$router.push(link);
      },
      viewWarning() {
        this.$router.push('/power/earlyWarning');
      }
    },
  };
</script>

<style scoped>
  #powerManage {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 0 25px 0;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .column {
    height: 100%;
  }

  .column1 {
    width: calc((100% - 40px) * 0.25);
  }

  .column2 {
    width: calc((100% - 40px) * 0.375);
  }

  .column2 .row,
  .column3 .row {
    border-radius: 10px;
    background-color: #ffffff;
    width: 100%;
  }

  .column2 .row1 {
    height: 100%;
  }

  .column3 {
    width: calc((100% - 40px) * 0.375);
  }

  .column3 .row1 {
    height: calc(100% - 360px);
    margin-bottom: 20px;
    position: relative;
  }

  .column3 .row2 {
    height: 340px;
    position: relative;
    background-color: transparent;
  }

  .linkGroup {
    width: 100%;
    height: 100%;
  }

  .linkGroup li {
    height: 102px;
    width: 100%;
    /* background-color: pink; */
    margin-bottom: 17px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    padding-left: 14%;
    line-height: 102px;
    position: relative;
    cursor: pointer;
  }

  .linkGroup li span {
    font-size: 24px;
    color: #ffffff;
  }

  .linkGroup li:first-child {
    background-image: url("../../assets/images/power/bg6.png");

  }

  .linkGroup li:nth-child(2) {
    background-image: url("../../assets/images/power/bg7.png");
  }

  .linkGroup li:nth-child(3) {
    background-image: url("../../assets/images/power/bg8.png");
    margin-bottom: 0px;
  }

  .linkGroup li img {
    position: absolute;
    right: 52px;
    top: 50%;
    transform: translateY(-50%);
  }

  .pieBox {
    width: 100%;
    /* background-color: pink; */
    height: calc(100% - 60px);
    position: relative;
  }

  .warningBox {
    width: calc(100% - 60px);
    margin: 15px auto 0;
    height: 78px;
    background: rgba(252, 59, 49, 0.05);
    border: 1px dashed #FF3F3F;
    border-radius: 10px;
    display: flex;
    justify-content: center;
  }

  .warningBoxIcon {
    width: 44px;
    height: 44px;
    background: linear-gradient(0deg, #FF3F3F 0%, #FF7E7E 100%);
    border-radius: 50%;
    text-align: center;
    padding-top: 7px;
    margin-right: 40px;
    margin: 15px 40px 0 0;
  }

  .warningBox>span {
    color: #505B75;
    font-size: 18px;
    margin: 23px 40px 0 0;
  }

  .warningBox>b {
    font-size: 42px;
    color: #FF3F3F;
    margin-top: 7px;
    cursor: pointer;
  }

  .detailBox {
    width: calc(100% - 60px);
    height: 80px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    display: flex;
    justify-content: space-between;
  }

  .detailBox li {
    text-align: center;
  }

  .detailBox li p {
    color: #464852;
    font-size: 18px;
  }

  .detailBox li p b {
    font-size: 30px;
    color: #2B67FC;
  }

  .detailBox li h5 {
    width: 105px;
    height: 28px;
    background: #F2F5FA;
    line-height: 28px;
    text-align: center;
    border-radius: 2px;
    font-size: 16px;
    color: #464852;
    margin-top: 10px;
  }

  .linkBtn {
    width: 209px;
    height: 54px;
    background: linear-gradient(0deg, #509cff 0%, #2863fc 100%);
    box-shadow: 0px 4px 10px 0px rgba(107, 126, 169, 0.38);
    border-radius: 27px;
    font-size: 22px;
    line-height: 54px;
    position: absolute;
    color: #ffffff;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    left: 9%;
    cursor: pointer;
    transition: all 0.3s;
  }

  .linkBtn:hover {
    box-shadow: 0px 4px 10px 0px rgba(40, 99, 252, 0.58);
  }

  .switchBox {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/power/powerBg1.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
  }

  .powerImg {
    width: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .powerImg.on {
    z-index: 2;
  }

  h4.tit {
    color: #ffffff;
    font-size: 22px;
    width: 100%;
    padding-left: 30px;
    height: 100px;
    line-height: 100px;
    font-weight: normal;
  }

  h5.tit {
    color: #1b2f61;
    font-size: 22px;
    line-height: 60px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 25px 0 30px;
    font-weight: normal;
  }

  h5.tit .iconfont {
    color: #98a6c6;
    cursor: pointer;
    font-size: 26px;
  }

  .switchBtn {
    width: 209px;
    height: 54px;
    text-align: center;
    line-height: 54px;
    border-radius: 27px;
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }

  .switchBtn.open {
    background: linear-gradient(0deg, #f7fcff 2%, #98e0f8 100%);
    color: #1e74a8;
    top: 43%;
  }

  .switchBtn.close {
    top: calc(43% + 76px);
    color: #ffffff;
    border: 1px solid #ffffff;
  }

  .cirBox {
    width: 20vw;
    height: 20vw;
    /* background-color: pink; */
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%);
  }

  .cirBox>img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cirBox .numBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* font-size: 52px; */
    color: #4087eb;
    width: 250px;
    height: 250px;
    text-align: center;
    /* line-height: 170px; */
    border-radius: 50%;
    z-index: 1;
    padding-top: 40px;
  }

  .cirBox .numBox .countNum {
    color: #2B67FC;
    font-size: 46px;
    font-weight: normal;
  }

  .cirBox .numBox h5 {
    font-size: 19px;
    color: #444C5B;
    font-weight: normal;
    margin: 4px 0 18px 0;
  }

  .cirBox .numBox p {
    width: 175px;
    height: 40px;
    background: linear-gradient(90deg, #2B67FC 0%, #4896F7 100%);
    border-radius: 18px;
    line-height: 38px;
    text-align: center;
    font-size: 17px;
    color: #ffffff;
    margin: 10px auto;
  }

  .cirBox .numBox p b {
    font-size: 22px;
    padding: 0 8px;
  }

  .cirBox .numBox.type1 {
    background-color: transparent;
    background-image: radial-gradient(#ffffff, #dceaf7, #96cbf7);
  }

  .cirBox .numBox.type2 {
    background-color: transparent;
    color: #18B68B;
    font-size: 34px;
    line-height: 85px;
  }

  .cirBox .numBg.type1 {
    width: 320px;
    height: 320px;
    /* background-image: radial-gradient(#ffffff,#ffffff,#96cbf7); */
    background-color: transparent;
    box-shadow: inset 0px 0px 50px 2px #bdcffe;
    border: 1px solid #68affe;
  }

  .cirBox.normal .numBg {
    background-color: #def8f5;
  }

  .cirBox.normal .numBox {
    color: #03b86d;
    font-size: 22px;
    line-height: 46px;
  }

  .cirBox.normal .numBox img {
    margin-top: 55px;
  }

  .cirBox.abnormal .numBg {
    background-color: #ffe4e4;
  }

  .cirBox.abnormal .numBox {
    color: #ff3f3f;
    font-size: 22px;
    line-height: 46px;
  }

  .cirBox.abnormal .numBox img {
    margin-top: 55px;
  }

  .cirBox .numBg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 262px;
    height: 262px;
    text-align: center;
    background-color: #e3ebff;
    border-radius: 50%;
  }

  .cirBox .cir1-1 {
    animation: cirRotate1 30s infinite linear;
    border-radius: 50%;
    z-index: 1;
    /* width: 360px;
  width: 360px; */
  }

  .cirBox .cir1-2 {
    /* animation: cirRotate2 6s infinite linear; */
    z-index: 2;
  }

  .cirBox .cir1-3 {
    animation: cirRotate2 6s infinite linear;
    z-index: 3;
  }

  .water_waves {
    width: 302px;
    height: 302px;
    border: 2px solid #4FD5CA;
    border-radius: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    box-shadow: 0px 0px 60px #558DF9 inset;
    background-color: #F1FDFA;
    z-index: 3;
  }

  .water_wave1 {
    /* background-image: radial-gradient(circle, #ffffff, #3b78e0, #88f1e7); */
    background-image: radial-gradient(circle, #8bacf1 10%, #045efa, #42ecdb 80%);

    position: absolute;
    width: 180%;
    height: 180%;
    top: 60%;
    left: -55%;
    border-radius: 40%;
    opacity: 0.53;
  }

  .water_wave2 {
    position: absolute;
    width: 200%;
    height: 200%;
    /* background-image: radial-gradient(circle, #ffffff, #326AFC, #11CBB6); */
    background-image: radial-gradient(circle, #8bacf1 10%, #045efa, #42ecdb 80%);
    top: 60%;
    left: -25%;
    border-radius: 35%;
    opacity: 0.53;
  }

  .water_wave1 {
    animation: inherit;
    animation-duration: 7s;
  }

  .water_wave2 {
    animation: inherit;
    animation-duration: 10s;
  }

  @keyframes water-waves {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .water_waves {
    animation: water-waves linear infinite;
  }

  @keyframes cirRotate1 {
    from {
      transform: translate(-50%, -50%) rotate(359deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  @keyframes cirRotate2 {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(359deg);
    }
  }

  @media screen and (max-width: 1900px) {
    .cirBox {
      transform: scale(0.8, 0.8) translate(-60%, -60%);
    }

    h5.tit .powerStatusTip {
      font-size: 14px;
    }

    h5.tit .powerStatusTip span {
      padding-left: 15px;
    }

    h5.tit .powerStatusTip span::before {
      width: 6px;
      height: 6px;
      left: 4px;
      top: 8px;
    }
  }

  @media screen and (min-width: 1930px) {
    .cirBox {
      transform: scale(1.2, 1.2) translate(-40%, -40%);
    }
  }
</style>