import { render, staticRenderFns } from "./PowerData.vue?vue&type=template&id=485b4fc7&scoped=true&"
import script from "./PowerData.vue?vue&type=script&lang=js&"
export * from "./PowerData.vue?vue&type=script&lang=js&"
import style0 from "./PowerData.vue?vue&type=style&index=0&id=485b4fc7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485b4fc7",
  null
  
)

export default component.exports