<template>
  <div id="container">   
    <el-card class="box-card">
    <div class="container">
      <div class="handle-box">
        <el-button
          type="danger"
          icon="el-icon-plus"
          class="handle-del"
          @click="handleWeather()"
        >推送天气</el-button>  
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="handle-del mr10"
          @click="handleDatetime()"
        >推送时间</el-button>       
        <el-input v-model="query.key" placeholder="单位" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        :highlight-current-row="true"        
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        
        <el-table-column label="ID" align="center" width="100">
          <template slot-scope="scope">
            <p @dblclick="handlerId(scope.$index, scope.row)"> {{scope.row.id}}</p> 
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称"  :show-overflow-tooltip="true"  align="center"></el-table-column>
        <el-table-column prop="city_no" label="城市编号" width="180"  align="center"></el-table-column>
        <el-table-column prop="manager" label="负责人" width="180" align="center"></el-table-column>
        <el-table-column prop="phone" label="电话" width="180" align="center"></el-table-column>      
        <el-table-column prop="orders" label="排序" width="180"  align="center"></el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
            <!--el-button
              type="success"
              icon="el-icon-plus"
              :disabled="scope.row.grade==3"
              @click="handleCreate(scope.$index, scope.row)"
            >新增</el-button-->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
          <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="query.page_index"
              :page-size="query.page_size"
              :total="pageTotal"
              @current-change="handlePageChange"
          ></el-pagination>
      </div>
    </div>
  </el-card>
    <!-- 编辑弹出框 -->
    <el-dialog :title="title" :visible.sync="editVisible" width="30%">
      <el-form ref="uform" :rules="rules" :model="uform" label-width="120px">           
        <el-form-item prop="id" label="编号" v-show="false">
          <el-input v-model="uform.id"></el-input>
        </el-form-item>
        <!--el-form-item prop="superior_id" label="上级" v-show="false">
          <el-input v-model="uform.superior_id"></el-input>
        </el-form-item-->
        <el-form-item prop="name" label="名称">
          <el-input v-model="uform.name"></el-input>
        </el-form-item>
        <el-form-item prop="short_name" label="简称" v-show="false">
          <el-input v-model="uform.short_name"></el-input>
        </el-form-item>
        <el-form-item prop="city_no" label="城市编号">
          <el-input v-model="uform.city_no"></el-input>
        </el-form-item>
        <el-form-item prop="manager" label="负责人">
          <el-input v-model="uform.manager"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="电话">
          <el-input v-model="uform.phone"></el-input>
        </el-form-item>     
        <el-form-item prop="orders" label="序号">
          <el-input v-model="uform.orders"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit('uform')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="ID修改" :visible.sync="editVisible2" width="30%">
      <el-form ref="uform2" :rules="rules2" :model="uform2" label-width="70px">          
        <el-form-item prop="id" label="旧编号"  v-show="false">
          <el-input v-model="uform2.id"></el-input>
        </el-form-item>  
        <el-form-item prop="nid" label="新编号">
          <el-input v-model="uform2.nid"></el-input>
        </el-form-item>       
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit2('uform2')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { fetchPost, fetchGet, fetchDelete } from "@/service/index";


export default {
  data() {
    return {
      tableData: [],
      query: {
        key:"",
        county:"",
        pageIndex: 1,
        pageSize: 10,
      },
      editVisible: false,
      editVisible2: false,
      title: "",      
      pageTotal:0,    
      uform: {
        id: 0,
        province:"",
        city:"",
        county:"",       
        name: "",
        city_no:"101010100",
        status: 0,
        orders: 1
      },
      rules: {          
        name: [{ required: true, message: "", trigger: "blur" }], 
        city_no: [{ required: true, message: "", trigger: "blur" }],         
      },
      uform2: {
        id: 0,
        nid: 0,
      },
      rules2: {
        id:[{ required: true, message: "", trigger: "blur" }],    
        nid: [{ required: true, message: "", trigger: "blur" }],         
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {    
    handlerId(index, row){
      this.uform2.id=row.id;
      this.uform2.nid=row.id;
      this.editVisible2=true;      
    },
    getData() {
      fetchGet("/api/base/company/list", this.query).then(res => {
        if (res.status) {
          this.tableData = res.data;
          this.pageTotal = res.count;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 触发搜索按钮
    handleSearch() {
      this.getData();
    },
    handleDelete(index, row) {
      if (row.is_menu) {
        this.$message.success("菜单项禁止删除！");
        return;
      }
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          fetchGet("/api/base/company/del/" + row.id).then(res => {
            if (res.status) {
              this.$message.success("删除成功");
              //this.tableData.splice(index, 1);
              this.getData();
            } else {
              this.$message.success(res.msg);
            }
          });
        })
        .catch(() => {
          console.log("出差了");
        });
    },    
    handleWeather(){
      fetchPost("/api/weather", this.uform).then(res => {   
          this.$message.success(res.msg);
      }).catch(err => {
        this.$message.success(`出错了:` + err);
      });
    },
    handleDatetime(){
      fetchPost("/api/datetime", this.uform).then(res => {   
          this.$message.success(res.msg);
      }).catch(err => {
        this.$message.success(`出错了:` + err);
      });
    },
    
    handleCreate(index, row) {
      this.title = "新增单位";
      this.editVisible = true;
      this.uform = {
        id: 0,
        name: "",       
        orders: 1
      };
    },    

    handleEdit(index, row) {
      this.idx = index;      
      this.uform = row;
      this.editVisible = true;
      this.title = "编辑信息";    
      this.uform.region=[row.province.toString(),row.city.toString(),row.county.toString()];   
    },    
    saveEdit(uform) {
      this.$refs[uform].validate(valid => {
        if (valid) {
          fetchPost("/api/base/company/edit", this.uform).then(res => {             
              if (res.status) {
                this.editVisible = false;
                this.$set(this.tableData, this.idx, this.uform);
                this.getData();
              }
              this.$message.success(res.msg);
            })
            .catch(err => {
              this.$message.success(`出错了:` + err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },    
    saveEdit2(uform2) {
      this.$refs[uform2].validate(valid => {
        if (valid) {
          fetchPost("/api/base/company/editid", this.uform2)
            .then(res => {             
              if (res.status) {
                this.editVisible2 = false;
                this.$set(this.tableData, this.idx, this.uform);
                this.getData();
              }
              this.$message.success(res.msg);
            })
            .catch(err => {
              this.$message.success(`出错了:` + err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handlePageChange(val) {
        this.$set(this.query, 'page_index', val);
        this.getData();
    },
  
  }
};
</script>

<style scoped>
#container{
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.box-card{
 padding-top: 15px;
}
.show-ellipsis {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.crumbs {
  padding-bottom: 5px;
}
.handle-box {
  margin-bottom: 15px;
  margin-top: -15px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
