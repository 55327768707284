<!--
 * @Author: ZS
 * @Date: 2022-11-16 11:08:31
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-16 11:08:32
 * @Description: 电源预警报警页面
-->
<template>
  <div id="powerUseReport">
    <div class="row">
      <div class="boxTit">
        <span>预警报警</span>
      </div>
      <div class="chartBox">
        <div class="tableBox">
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column
              prop="xuhao"
              label="序号"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="type" label="报警类型" align="center">
            </el-table-column>
            <el-table-column
              prop="electricCurrent"
              label="故障电流"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="time" label="故障时间" align="center">
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <div class="status1 status" v-show="scope.row.status == 1">
                  待受理
                </div>
                <div class="status2 status" v-show="scope.row.status == 2">
                  已受理
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="fenye">
          <el-pagination background layout="prev, pager, next" :total="1000">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          xuhao: 1,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 1,
        },
        {
          xuhao: 2,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 2,
        },
        {
          xuhao: 3,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 1,
        },
        {
          xuhao: 4,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 1,
        },
        {
          xuhao: 5,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 2,
        },
        {
          xuhao: 6,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 1,
        },
        {
          xuhao: 7,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 1,
        },
        {
          xuhao: 8,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 1,
        },
        {
          xuhao: 9,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 1,
        },
        {
          xuhao: 10,
          type: "漏电报警",
          electricCurrent: "71.00mA",
          time: "2022-10-23 12:30:23",
          status: 1,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    choose(idx, data) {},
  },
};
</script>
<style scoped>
#powerUseReport {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px 0 25px 0;
  height: 100%;
}
.row {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
}

.boxTit {
  position: relative;
  height: 88px;
  display: flex;
  padding: 15px 20px;
  width: 100%;
  justify-content: space-between;
}

.boxTit span {
  line-height: 58px;
  color: rgba(27, 47, 97, 1);
  font-size: 22px;
  padding-left: 10px;
}

.chartBox {
  width: 100%;
  height: calc(100% - 88px);
}

.tableBox {
  width: 100%;
  padding: 0 20px 0 25px;
}

.tableBox /deep/ .el-table .el-table__cell {
  padding: 6px 0;
  border-bottom: 1px solid #eaedf2;
}

.tableBox
  /deep/.el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: rgba(17, 104, 218, 0.05);
}

.tableBox /deep/.el-table thead th {
  background: rgba(80, 168, 255, 0.1);
  border-top: 2px solid #50a8ff;
}

.tableBox .status {
  width: 70px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 13px;
  color: #ffffff;
  margin: 0 auto;
}

.tableBox .status.status1 {
  background-color: #ffa32b;
}

.tableBox .status.status2 {
  background-color: #03b86d;
}
.fenye {
  padding: 20px 20px 20px 25px;
}
</style>
