<!--
 * @Author: ZS
 * @Date: 2022-11-16 09:26:47
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-16 09:37:17
 * @Description: 电源主页
-->
<template>
  <div id="power">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'power',

    data() {
      return {

      };
    },

    mounted() {

    },

    methods: {

    },
  };
</script>

<style lang="css" scoped>
  #power {
    width: 100%;
    height: 100%;
  }
</style>