<template>
    <div id="container">   
      <el-card class="box-card">
      <div class="container">
        <div class="handle-box">          
          <el-button
            type="danger"
            icon="el-icon-plus"
            class="handle-del"
            @click="handleOpen()"
          >打开大屏</el-button>  
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="handleInit()"
          >初始化数据</el-button>       
          <el-input v-model="query.key" placeholder="单位" class="handle-input mr10"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </div>
        <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        :highlight-current-row="true"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>        
        <el-table-column prop="name" label="名称"></el-table-column>                
        <el-table-column prop="id" label="ID"  width="55" align="center"></el-table-column>         
        <el-table-column prop="value" label="值" align="center"></el-table-column>       
        <el-table-column prop="orders" label="排序" width="100" align="center"></el-table-column>
        <el-table-column label="录入时间" align="center"  width="160"  :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.created|dateformat('YYYY-MM-DD HH:mm:ss')}}</template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
            v-if="scope.row.superior_id>0"
            size="small"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>   
            <el-button
            v-if="scope.row.superior_id==0"
            size="small"
              type="danger"
              icon="el-icon-plus"
              @click="handleCreate(scope.$index, scope.row)"
            >新增</el-button>           
          </template>
        </el-table-column>
        </el-table>
        <!--div class="pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page="query.page_index"
                :page-size="query.page_size"
                :total="pageTotal"
                @current-change="handlePageChange"
            ></el-pagination>
        </div-->
      </div>
      </el-card>

      <!-- 编辑弹出框 -->
      <el-dialog :title="title" :visible.sync="editVisible" width="30%">
        <el-form ref="uform" :rules="rules" :model="uform" label-width="100px">           
          <el-form-item prop="id" label="编号" v-show="false">
            <el-input v-model="uform.id"></el-input>
          </el-form-item>
          <el-form-item prop="superior_id" label="上级" v-show="false">
            <el-input v-model="uform.superior_id"></el-input>
          </el-form-item>
          <el-form-item prop="name" label="名称">
            <el-input v-model="uform.name"></el-input>
          </el-form-item>          
          <el-form-item prop="value" label="值">
            <el-input v-model="uform.value"></el-input>
          </el-form-item>          
          <el-form-item prop="orders" label="序号">
            <el-input v-model="uform.orders"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit('uform')">确 定</el-button>
        </span>
      </el-dialog>  
  

    </div>
  </template>
  <script>
  import { fetchPost, fetchGet, fetchDelete } from "@/service/index";
  
  
  export default {
    data() {
      return {
        tableData: [],
        query: {
          key:"",        
          pageIndex: 1,
          pageSize: 20,
        },
        editVisible: false,       
        title: "",      
        pageTotal:0,    
        uform: {
          id: 0,     
          kind:0,
          superior_id:0,
          company_id:0,      
          name: "",          
          value: 0,
          orders: 1
        },
        rules: {          
          name: [{ required: true, message: "", trigger: "blur" }], 
          value: [{ required: true, message: "", trigger: "blur" }],         
        },        
      };
    },
    created() {
      this.getData();
    },
    methods: {          
      getData() {
        fetchGet("/api/base/screen/list", this.query).then(res => {         
          if (res.status) {
            this.tableData = res.data;
            this.pageTotal = res.count;
          } else {
            this.$message.error(res.msg);
          }
        });
      },     
      handleSearch() {
        this.getData();
      },
      handleDelete(index, row) {
        this.$confirm("确定要删除吗？", "提示", {
          type: "warning"
        }).then(() => {
          console.log("/api/base/screen/del/" + row.id);
            fetchGet("/api/base/screen/del/" + row.id).then(res => {
              if (res.status) {
                this.$message.success("删除成功");               
                this.getData();
              } else {
                this.$message.success(res.msg);
              }
            });
          })
          .catch(() => {
            console.log("出差了");
          });
      },    
      handleInit(){
        fetchPost("/api/base/screen/init", this.uform).then(res => {   
            this.$message.success(res.msg);
            this.getData();
        }).catch(err => {
          this.$message.success(`出错了:` + err);
        });
      },
      handleOpen(){
        window.location.href="/screen/"+localStorage.getItem('company_id');
      },      
      handleCreate(index, row) {
        this.title = "新增";
        this.editVisible = true;
        this.uform = {
          id: 0,
          kind:row.kind,
          superior_id:row.id,
          name: "",       
          orders: 1
        };
      },      
      handleEdit(index, row) {
        this.idx = index;      
        this.uform = row;
        this.editVisible = true;
        this.title = "编辑信息";    
        this.uform.region=[row.province.toString(),row.city.toString(),row.county.toString()];   
      },    
      saveEdit(uform) {
        this.$refs[uform].validate(valid => {
          if (valid) {
            fetchPost("/api/base/screen/edit", this.uform).then(res => {             
                if (res.status) {
                  this.editVisible = false;
                  this.$set(this.tableData, this.idx, this.uform);
                  this.getData();
                }
                this.$message.success(res.msg);
              }).catch(err => {
                this.$message.success(`出错了:` + err);
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }, 
      handlePageChange(val) {
          this.$set(this.query, 'page_index', val);
          this.getData();
      },    
    }
  };
  </script>
  
  <style scoped>
  #container{
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .box-card{
   padding-top: 15px;
  }
  .show-ellipsis {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .crumbs {
    padding-bottom: 5px;
  }
  .handle-box {
    margin-bottom: 15px;
    margin-top: -15px;
  }
  
  .handle-select {
    width: 120px;
  }
  
  .handle-input {
    width: 300px;
    display: inline-block;
  }
  .table {
    width: 100%;
    font-size: 14px;
  }
  .red {
    color: #ff0000;
  }
  .mr10 {
    margin-right: 10px;
  }
  .table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
  }
  </style>
  