import { render, staticRenderFns } from "./DisinfectManage.vue?vue&type=template&id=0705c723&scoped=true&"
import script from "./DisinfectManage.vue?vue&type=script&lang=js&"
export * from "./DisinfectManage.vue?vue&type=script&lang=js&"
import style0 from "./DisinfectManage.vue?vue&type=style&index=0&id=0705c723&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0705c723",
  null
  
)

export default component.exports