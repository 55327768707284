<template>
  <div id="lightManage">
    <div class="column column1">
      <div class="switchBox">
        <h4 class="tit">窗帘管理</h4>
        <img src="../../assets/images/curtain/curtain_close.png" alt="" class="lightImg" />
        <img src="../../assets/images/curtain/curtain_open.png" alt="" class="lightImg on" v-show="this.$store.state.curtainIsOpen" />
        <div class="switchBtn open noselect" @click="open()">打开</div>
        <div class="switchBtn close noselect" @click="close()">关闭</div>
      </div>
    </div>
    <div class="column column2">
      <div class="row row1">
        <h5 class="tit">
          <span>{{this.$store.state.regionName}}接入数量</span><i class="iconfont icon-more1"></i>
        </h5>
        <div class="pieBox">
          <div class="cirBox">
             <img src="../../assets/images/cir1-1.png" alt="" class="cir1-1" style="width:346px;height:346px" />
             <img src="../../assets/images/cir1-2.png" alt="" class="cir1-2" style="width:260px;height:260px" />
             <img src="../../assets/images/cir1-3.png" alt="" class="cir1-3" style="width:156px;height:156px" />
            <div class="numBox">
              <countTo :startVal='0' :endVal='this.$store.state.regionNo' :duration='1000'></countTo>
            </div>
            <div class="numBg"></div>
          </div>
        </div>
      </div>
      <div class="row row2">
        <h5 class="tit">
          <span>耗电数量</span>
        </h5>
        <div class="barBox">
          <div class="box">
            <div class="box1">
              <div class="box2" :style="{ width: barwidth }">
                <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="barNums">
           <countTo :startVal='0' :endVal='num3' :duration='1000'></countTo><span>度</span>
          </div>
        </div>
      </div>
    </div>
    <div class="column column3">
      <div class="row row1">
        <h5 class="tit">
          <span>窗帘接入数量</span><i class="iconfont icon-more1"></i>
        </h5>
        <div class="pieBox">
          <div class="cirBox">
            <img src="../../assets/images/cir2-1.png" alt="" class="cir1-1" style="width:340px;height:340px" />
            <img src="../../assets/images/cir2-3.png" alt="" class="cir1-2" style="width:233px;height:233px" />
            <img src="../../assets/images/cir2-2.png" alt="" class="cir1-2" style="width:256px;height:256px" />
            <div class="numBox type1">
              <countTo :startVal='0' :endVal='this.$store.state.curtainNo' :duration='1000'></countTo>
            </div>
            <div class="numBg type1"></div>
          </div>
        </div>
      </div>
      <div class="row row2">
        <h5 class="tit">
          <span>状态提示</span>
        </h5>
        <ul class="lightStatus">
          <li>
            <img src="../../assets/images/lightStatus3.png" alt="" />
            正常
          </li>
          <li>
            <img src="../../assets/images/lightStatus2.png" alt="" />
            异常
          </li>
          <li>
            <img src="../../assets/images/lightStatus1.png" alt="" />
            损坏
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchPost, fetchGet, fetchDelete, hasAction } from "@/service/index";
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    data() {
      return {
        companyId: localStorage.getItem('company_id'),
        name:"办公室",   
        barwidth: "0%",
        num1:76,
        num2:120,
        num3:23045,
        time: 2
      };
    },
    created(){
      //this.num1=0;
      //this.num2=0;

      //console.log(this.$store.state.leftActiveMenu.children.length);

      //this.$store.state.leftActiveMenu.forEach(e=>{
      // this.num1=this.num1+e.lightNo;
      //});
   },
    methods: {
      open() {
        if(this.$store.state.curtainIsOpen){
          this.$message.error("请先关闭"); 
          return;
        }

        this.$store.commit("setCurtainOpen", true); 

        var ids="";
        
        this.$store.state.leftActiveMenu.children.forEach(e=>{
            ids=ids+","+e.clientId;
            fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+e.roomId,deviceType:"curtain",deviceId:0,"status":1}).then((data) => {           
                if (data.status) {
                            
                } else {               
                  this.$message.error(data.msg);               
                }
              }).catch((err) => {
                console.log(err);
                this.$message.error(err);              
              }); 
        }); 

        this.$store.commit("setBatCurtainOpen", {clientIds:ids,isOpen:true}); 
        
      },
      close() {
        if(!this.$store.state.curtainIsOpen){
          this.$message.error("请先打开"); 
          return;
        }
        
        this.$store.commit("setCurtainOpen", false); 

        var ids="";        

        this.$store.state.leftActiveMenu.children.forEach(e=>{
          ids=ids+","+e.clientId;
          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+e.roomId,deviceType:"curtain",deviceId:0,"status":0}).then((data) => {           
              if (data.status) {
                          
              } else {               
                this.$message.error(data.msg);               
              }
            }).catch((err) => {
              console.log(err);
              this.$message.error(err);              
            }); 
        }); 

        this.$store.commit("setBatCurtainOpen", {clientIds:ids,isOpen:false}); 
      },
      plgl() {
        this.$router.push("/curtain/batchM");
      },
      yjcx() {
        this.$router.push("/curtain/curtainQ");
      },
    },
    mounted() {
      setTimeout(() => {
        this.barwidth = "80%";
      }, 300);
    },
  };
</script>

<style scoped>
  #lightManage {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 0 25px 0;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .column {
    height: 100%;
  }

  .column1 {
    width: calc((100% - 40px) * 0.25);
  }

  .column2 {
    width: calc((100% - 40px) * 0.375);
  }

  .column2 .row,
  .column3 .row {
    border-radius: 20px;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 20px;
  }

  .column2 .row1 {
    height: calc((100% - 20px) * 0.761);
  }

  .column2 .row2 {
    height: calc((100% - 20px) * 0.239);
    position: relative;
    background-color: #ffffff;
  }

  .column3 {
    width: calc((100% - 40px) * 0.375);
  }

  .column3 .row1 {
    height: calc((100% - 20px) * 0.761);
  }

  .column3 .row2 {
    height: calc((100% - 20px) * 0.239);
    position: relative;
    background-color: #ffffff;
  }

  .pieBox {
    width: 100%;
    /* background-color: pink; */
    height: calc(100% - 80px);
    position: relative;
  }

  .linkBtn {
    width: 209px;
    height: 54px;
    background: linear-gradient(0deg, #509cff 0%, #2863fc 100%);
    box-shadow: 0px 4px 10px 0px rgba(107, 126, 169, 0.38);
    border-radius: 27px;
    font-size: 22px;
    line-height: 54px;
    position: absolute;
    color: #ffffff;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    left: 9%;
    cursor: pointer;
    transition: all 0.3s;
  }

  .linkBtn:hover {
    box-shadow: 0px 4px 10px 0px rgba(40, 99, 252, 0.58);
  }

  .switchBox {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/xdBg.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
  }

  .lightImg {
    width: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    position: absolute;
    left: 0;
    top: 55px;
    z-index: 1;
    opacity: 0.8;
  }

  .lightImg.on {
    z-index: 2;
  }

  h4.tit {
    color: #ffffff;
    font-size: 22px;
    width: 100%;
    padding-left: 30px;
    height: 100px;
    line-height: 100px;
    font-weight: normal;
  }

  h5.tit {
    color: #1b2f61;
    font-size: 22px;
    line-height: 80px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0 25px 0 30px;
    font-weight: normal;
  }

  .row2 h5.tit {
    line-height: 65px;
    height: 65px;
  }

  h5.tit .iconfont {
    color: #98a6c6;
    cursor: pointer;
    font-size: 26px;
  }

  .barBox {
    display: flex;
    color: #757781;
    width: 87%;
    position: absolute;
    justify-content: space-between;
    left: 5%;
    top: 50%;
  }

  .barBox .box {
    width: 65%;
    height: 34px;
    border: 1px solid #c6d1e4;
    border-radius: 17px;
    padding: 3px;
    position: relative;
    margin-top: 4px;
  }

  .barBox .box .box1 {
    width: 100%;
    height: 100%;
    border-radius: 14px;
    background-color: #e5eaf3;
  }

  .barBox .box2 {
    height: 100%;
    border-radius: 14px;
    overflow: hidden;
    transition: all 2s;
    position: relative;
    width: 0px;
  }

  .barBox .box2 ul {
    display: flex;
    position: absolute;
    left: -10px;
    top: -8px;
    height: 42px;
  }

  .barBox .box2 li {
    width: 10px;
    height: 100%;
    transform: rotate(45deg);
  }

  .barBox .box2 li:nth-child(2n -1) {
    background-color: #3d6edd;
  }

  .barBox .box2 li:nth-child(2n) {
    background-color: #5e87de;
  }

  .barBox .barNums {
    width: 35%;
    font-size: 26px;
    color: #464852;
    font-weight: 700;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .barBox .barNums span:last-child {
    font-size: 16px;
    padding-left: 10px;
    font-weight: normal;
  }

  .lightStatus {
    display: flex;
    color: #757781;
    font-size: 20px;
    width: 100%;
    position: absolute;
    justify-content: center;
    left: 0;
    top: 50%;
  }

  .lightStatus li {
    width: 31%;
    text-align: center;
  }

  .lightStatus img {
    margin-right: 22px;
  }

  .switchBtn {
    width: 209px;
    height: 54px;
    text-align: center;
    line-height: 54px;
    border-radius: 27px;
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }

  .switchBtn.open {
    background: linear-gradient(0deg, #f7fcff 2%, #98e0f8 100%);
    color: #1e74a8;
    top: 45%;
  }

  .switchBtn.close {
    top: calc(45% + 76px);
    color: #ffffff;
    border: 1px solid #ffffff;
  }

  .cirBox {
    width: 20vw;
    height: 20vw;
    /* background-color: pink; */
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%);
  }

  .cirBox img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cirBox .numBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 52px;
    color: #4087eb;
    width: 220px;
    height: 220px;
    text-align: center;
    line-height: 220px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    z-index: 1;
  }

  .cirBox .numBox.type1 {
    background-color: transparent;
    background-image: radial-gradient(#ffffff, #dceaf7, #96cbf7);
     width: 232px;
     height: 232px;
  }

  .cirBox .numBg.type1 {
   width: 276px;
   height: 276px;
    /* background-image: radial-gradient(#ffffff,#ffffff,#96cbf7); */
    background-color: transparent;
    box-shadow: inset 0px 0px 50px 2px #bdcffe;
    border: 1px solid #68affe;
  }

  .cirBox .numBg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 288px;
    height: 288px;
    text-align: center;
    background-color: #e3ebff;
    border-radius: 50%;
  }

  .cirBox .cir1-1 {
    animation: cirRotate1 30s infinite linear;
    border-radius: 50%;
    z-index: 1;
    width: 360px;
    width: 360px;
  }

  .cirBox .cir1-2 {
    animation: cirRotate2 6s infinite linear;
    z-index: 2;
  }

  .cirBox .cir1-3 {
    animation: cirRotate2 6s infinite linear;
    z-index: 3;
  }

  @keyframes cirRotate1 {
    from {
      transform: translate(-50%, -50%) rotate(359deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  @keyframes cirRotate2 {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(359deg);
    }
  }

  @media screen and (max-width: 1900px) {
    .cirBox {
      transform: scale(0.8, 0.8) translate(-60%, -60%);
    }
  }

  @media screen and (min-width: 1930px) {
    .cirBox {
      transform: scale(1.2, 1.2) translate(-40%, -40%);
    }
  }
</style>