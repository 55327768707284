<template>
  <div id="disinfeciManage">
    <div class="column column1">
      <div class="switchBox">
        <h4 class="tit">消毒管理</h4>
        <img
          src="../../assets/images/xiaoduClose.png"
          alt=""
          class="disinfeciImg"
        />
        <img
          src="../../assets/images/xiaoduOn.png"
          alt=""
          class="disinfeciImg on"
          v-show="this.$store.state.disinfectIsOpen"
        />
        <div class="switchBtn open noselect" @click="open()">打开</div>
        <div class="switchBtn close noselect" @click="close()">关闭</div>
      </div>
    </div>
    <div class="column column2">
      <div class="row row1">
        <h5 class="tit">
          <span>消毒机接入科室数量</span><i class="iconfont icon-more1"></i>
        </h5>
        <div class="pieBox">
          <div class="cirBox">
            <img src="../../assets/images/cir1-1.png" alt="" class="cir1-1" style="width:346px;height:346px" />
            <img src="../../assets/images/cir1-2.png" alt="" class="cir1-2" style="width:260px;height:260px" />
            <img src="../../assets/images/cir1-3.png" alt="" class="cir1-3" style="width:156px;height:156px" />
            <div class="numBox">
              <countTo :startVal='0' :endVal='this.$store.state.disinfectNoAll' :duration='1000'></countTo>
            </div>
            <div class="numBg"></div>
          </div>
        </div>
      </div>
      <div class="row row2">
        <div class="linkBtn noselect" @click="plgl()">批量管理</div>
      </div>
    </div>
    <div class="column column3">
      <div class="row row1">
        <h5 class="tit">
          <span>消毒设备数量</span><i class="iconfont icon-more1"></i>
        </h5>
        <div class="pieBox">
          <div class="cirBox">
            <img src="../../assets/images/cir2-1.png" alt="" class="cir1-1" style="width:340px;height:340px" />
            <img src="../../assets/images/cir2-3.png" alt="" class="cir1-2" style="width:233px;height:233px" />
            <img src="../../assets/images/cir2-2.png" alt="" class="cir1-2" style="width:256px;height:256px" />
            <div class="numBox type1">
               <countTo :startVal='0' :endVal='this.$store.state.disinfectNoAll' :duration='1000'></countTo>
            </div>
            <div class="numBg type1"></div>
          </div>
        </div>
      </div>
      <div class="row row2">
        <div class="linkBtn noselect" @click="yjcx()">一键查询</div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
import { fetchPost, fetchGet, fetchDelete, hasAction } from "@/service/index";

export default {
  components: { countTo },
  data() {
    return {
      companyId: localStorage.getItem('company_id'),      
      num1:56,
      num2:120
    };
  },
  methods: {
    open() {
      if(this.$store.state.disinfectIsOpen){
        this.$message.error("请先关闭"); 
        return;
      }

      this.$store.commit("setDisinfectOpen", true); 

      var ids="";
     
      this.$store.state.leftMenu.forEach(e=>{
        e.children?.forEach(c=>{     
          ids=ids+","+c.clientId;
          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+c.roomId,deviceType:"disinfect",deviceId:1,"status":1,"value":800}).then((data) => {           
                if (data.status) {
                            
                } else {               
                  this.$message.error(data.msg);               
                }
            }).catch((err) => {
              console.log(err);
              this.$message.error(err);              
            });  
        });
      }); 

      this.$store.commit("setBatDisinfectOpen", {clientIds:ids,isOpen:true}); 
    },
    close() {
      if(!this.$store.state.disinfectIsOpen){
        this.$message.error("请先打开"); 
        return;
      }

      this.$store.commit("setDisinfectOpen", false); 
     
      var ids="";

      this.$store.state.leftMenu.forEach(e=>{
        e.children?.forEach(c=>{     
          ids=ids+","+c.clientId;
          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+c.roomId,deviceType:"disinfect",deviceId:1,"status":0,"value":800}).then((data) => {           
                if (data.status) {
                            
                } else {               
                  this.$message.error(data.msg);               
                }
            }).catch((err) => {
              console.log(err);
              this.$message.error(err);              
            });  
        });
      }); 

      this.$store.commit("setBatDisinfectOpen", {clientIds:ids,isOpen:false}); 

    },
    plgl() {
      this.$router.push("/disinfect/batchM");
    },
    yjcx() {
      this.$router.push("/disinfect/disinfectQ");
    },
  },
};
</script>

<style scoped>
#disinfeciManage {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px 0 25px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.column {
  height: 100%;
}
.column1 {
  width: calc((100% - 40px) * 0.25);
}
.column2 {
  width: calc((100% - 40px) * 0.375);
}
.column2 .row,
.column3 .row {
  border-radius: 20px;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 20px;
}
.column2 .row1 {
  height: calc((100% - 20px) * 0.761);
}
.column2 .row2 {
  height: calc((100% - 20px) * 0.239);
  background-image: url("../../assets/images/plgl.png");
  background-repeat: no-repeat;
  background-size: 101% 102%;
  background-position: 0 0;
  position: relative;
}
.column3 {
  width: calc((100% - 40px) * 0.375);
}
.column3 .row1 {
  height: calc((100% - 20px) * 0.761);
}
.column3 .row2 {
  height: calc((100% - 20px) * 0.239);
  background-image: url("../../assets/images/yjcx.png");
  background-repeat: no-repeat;
  background-size: 101% 102%;
  background-position: 0 -2px;
  position: relative;
}
.pieBox {
  width: 100%;
  /* background-color: pink; */
  height: calc(100% - 80px);
  position: relative;
}
.linkBtn {
  width: 209px;
  height: 54px;
  background: linear-gradient(0deg, #509cff 0%, #2863fc 100%);
  box-shadow: 0px 4px 10px 0px rgba(107, 126, 169, 0.38);
  border-radius: 27px;
  font-size: 22px;
  line-height: 54px;
  position: absolute;
  color: #ffffff;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  left: 9%;
  cursor: pointer;
  transition: all 0.3s;
}
.linkBtn:hover {
  box-shadow: 0px 4px 10px 0px rgba(40, 99, 252, 0.58);
}
.switchBox {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/xdBg.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  overflow: hidden;
}
.disinfeciImg {
  width: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 1;
  opacity: 0.8;
}
.disinfeciImg.on {
  z-index: 2;
}
h4.tit {
  color: #ffffff;
  font-size: 22px;
  width: 100%;
  padding-left: 30px;
  height: 100px;
  line-height: 100px;
  font-weight: normal;
}
h5.tit {
  color: #1b2f61;
  font-size: 22px;
  line-height: 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px 0 30px;
  font-weight: normal;
}
h5.tit .iconfont {
  color: #98a6c6;
  cursor: pointer;
  font-size: 26px;
}
.switchBtn {
  width: 209px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
.switchBtn.open {
  background: linear-gradient(0deg, #f7fcff 2%, #98e0f8 100%);
  color: #1e74a8;
  top: 45%;
}
.switchBtn.close {
  top: calc(45% + 76px);
  color: #ffffff;
  border: 1px solid #ffffff;
}
.cirBox {
  width: 20vw;
  height: 20vw;
  /* background-color: pink; */
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
}
.cirBox img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cirBox .numBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 52px;
  color: #4087EB;
  width: 220px;
  height: 220px;
  text-align: center;
  line-height: 220px;
  background-color: rgba(255, 255, 255,.9);
  border-radius: 50%;
  z-index: 1;
}
.cirBox .numBox.type1 {
  background-color: transparent;
  background-image: radial-gradient(#ffffff,#dceaf7,#96cbf7);
  width: 232px;
  height: 232px;

}
.cirBox .numBg.type1 {
  width: 276px;
  height: 276px;
  /* background-image: radial-gradient(#ffffff,#ffffff,#96cbf7); */
  background-color: transparent;
  box-shadow: inset 0px 0px 50px 2px #BDCFFE;
  border: 1px solid rgba(104, 175, 254,.3);
}
.cirBox .numBg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 288px;
  height: 288px;
  text-align: center;
  background-color: #E3EBFF;
  border-radius: 50%;
}
.cirBox .cir1-1 {
  animation: cirRotate1 30s infinite linear;
  border-radius: 50%;
  z-index: 1;
}
.cirBox .cir1-2 {
  animation: cirRotate2 6s infinite linear;
  z-index: 2;
}
.cirBox .cir1-3 {
  animation: cirRotate2 6s infinite linear;
  z-index: 3;
}
@keyframes cirRotate1 {
  from {
    transform: translate(-50%, -50%) rotate(359deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
@keyframes cirRotate2 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}
@media screen and (max-width: 1900px) {
  .cirBox {
    transform: scale(0.8, 0.8) translate(-60%, -60%);
  }
}
@media screen and (min-width: 1930px) {
  .cirBox {
    transform: scale(1.2, 1.2) translate(-40%, -40%);
  }
}
</style>