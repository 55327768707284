<!--
 * @Author: ZS
 * @Date: 2022-09-22 15:28:09
 * @LastEditors: ZS
 * @LastEditTime: 2022-10-09 15:38:35
 * @Description: 头部内容
-->
<template>
  <div class="headerContent">
    <div class="left">
      <div class="logoGroup">
        <div class="logo">
          <img src="../assets/images/headerImg.png" alt="" />
        </div>
        <div class="unitName">
          <p>{{company}}</p>
          <p>校园管理中心</p>
          <!-- <img src="../assets/images/unitName.png" alt="" /> -->
        </div>
      </div>
    </div>
    <div class="right">
      <ul class="nav">
        <li v-for="(item, index) in navData" :key="index">
          <div
            @click="navClick(index, item.path)"
            :class="[{ active: currentPath.indexOf(item.path)>-1&& item.path != '' }]"
          >
            <div class="text">
              <i class="iconfont" :class="item.icon"></i>
              <p>{{ item.name }}</p>
            </div>
            <img src="../assets/images/hoverbg.png" alt="" />
          </div>
        </li>
      </ul>
      <div class="breadcrumb" :class="{ first: navIndex == 0 }">
        <div class="leftArrow" @click="$router.go(-1)">
          <div class="iconfont icon-zuojiantou"></div>
        </div>
        <div class="breadList">
          <i class="el-icon-s-home"></i>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!-- <el-breadcrumb-item :to="{ path: '/light' }">首页</el-breadcrumb-item> -->
            <el-breadcrumb-item
              v-for="item in sepalists"
              :key="item.path"
            >
              <router-link :to="item.path">{{ item.meta.title }}</router-link>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div id="yjbx" class="noselect" @click="reportRepair()" v-show ="currentRouteName == 'lightM'||currentRouteName =='disinfectM'">一键报修</div>
        <div id="more" class="iconfont icon-more1"  v-show ="currentRouteName == 'lightB' ||currentRouteName == 'lightQ'||currentRouteName == 'disinfectQ'||currentRouteName == 'disinfectB'  ">

        </div>
        <div class="rightArrow"  @click="$router.go(-1)">
          <div class="iconfont icon-youjiantou"></div>
        </div>
      </div>
      <div class="userInfo noselect">
        <el-popover placement="bottom" width="100" trigger="click">
          <ul class="infoBox">
            <li @click="region()">
              <a href="javascript:;">单元管理</a>
            </li>
            <li @click="device()">
              <a href="javascript:;">中控管理</a>
            </li>
            <li @click="timing()">
              <a href="javascript:;">定时任务</a>
            </li>
            <li @click="user()">
              <a href="javascript:;">账号管理</a>
            </li>
            <li @click="com()">
              <a href="javascript:;">单位管理</a>
            </li>
            <li @click="screen()">
              <a href="javascript:;">大屏数据</a>
            </li>
            <li @click="password()">
              <a href="javascript:;">修改密码</a>
            </li>
             <li @click="logout()">
               <a href="javascript:;">退出登录</a>
             </li>
          </ul>
          <div slot="reference" class="info" @click="arrowFlag = !arrowFlag">
            <img src="../assets/images/userImg.jpg" />{{ userName
            }}<i
              :class="[
                { 'el-icon-caret-bottom': arrowFlag },
                { 'el-icon-caret-top': !arrowFlag },
              ]"
            ></i>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>

let sseClient;

export default {
  data() {
    return {
      navData: [
        {
          name: "我的",
          icon: "icon-wode",
          path: "",
        },
        {
          name: "电源",
          icon: "icon-dianyuan",
          path: "/power",
        },
        {
          name: "灯光",
          icon: "icon-ai251",
          path: "/light",
        },
        {
          name: "消毒",
          icon: "icon-classxiaoduye",
          path: "/disinfect",
        },
        {
          name: "空调",
          icon: "icon-gongneng",
          path: "/ac",
        },
        {
          name: "窗帘",
          icon: "icon-changguanyuyue",
          path: "/curtain",
        },
        {
          name: "广播",
          icon: "icon-weibiaoti555",
          path: "",
        },
        {
          name: "绿化",
          icon: "icon-GIS-tulitucengicon_lvhuabiao",
          path: "",
        },       
        {
          name: "停车场",
          icon: "icon-tingchechang",
          path: "",
        },
        {
          name: "安防",
          icon: "icon-anquan",
          path: "",
        },
      ],
      arrowFlag: true,
      userName: "admin",
      company:"",
      sepalists: [],
      currentRouteName:'',
      navIndex:'',
      currentPath:'',
      sseClient:null,
    };
  },
  created() {
    //console.log(this.$route.matched);
    this.sepalists = this.$route.matched; //获取路由内的全部信息
    this.currentPath = this.$route.path
    
    this.userName=localStorage.getItem("user_name");
    this.company=localStorage.getItem("company");
  },
  mounted() {
    if (this.currentRouteName == '') {
      this.currentRouteName = this.$router.currentRoute.name
      console.log(this.$router.currentRoute)
    }

    var cid="client-"+localStorage.getItem("company_id");
   
    sseClient = this.$sse.create({
      url: '/events/'+cid,
      format: 'json',
      withCredentials: false,
      polyfill: true,
      forcePolyfill:true,      
    });

    sseClient.on('error', (err) => {
      console.error('发生错误', err);    
      this.$message.error(err);       
    });

  
    sseClient.on('message',  (msg) =>{      
      if(msg.device_type=="connected"){
        this.$store.commit("connected", msg); 
        console.log("event:connected,"+msg);   
      }else if(msg.device_type=="disconnect"){
        this.$store.commit("disconnect", msg); 
        console.log("event:disconnect,"+msg);   
      }else if(msg.device_type=="scene"){
        this.$store.commit("sceneStatus", msg); 
        console.log("event:sceneStatus,"+msg);   
      }else if(msg.device_type=="disinfect"){
        this.$store.commit("disinfectStatus", msg); 
        console.log("event:disinfectStatus,"+msg);   
      }else if(msg.device_type=="power"){
        this.$store.commit("powerStatus", msg); 
        console.log("event:powerStatus,"+msg);   
      }else if(msg.device_type=="ac"){
        this.$store.commit("acStatus", msg); 
        console.log("event:acStatus,"+msg);   
      }else if(msg.device_type=="curtain"){
        this.$store.commit("curtainStatus", msg); 
        console.log("event:curtainStatus,"+msg);   
      }else{
        console.log(msg);   
      }
    });

    sseClient.connect().then(sse => {        
        // Unsubscribes from event-less messages after 7 seconds
        setTimeout(() => {
          //sseClient.off('message', this.handleMessage);
          //console.log('Stopped listening to event-less messages!');
          //this.$message.error("SSE TimeOut");  
          console.error('连接失败：SSE TimeOut');
        }, 30000);       
      }).catch((err) => {       
        console.error('连接失败：', err);
        this.$message.error(err);    
      });
  }, 
  beforeDestroy(){   
   sseClient.disconnect();   
  },
  methods: {   
    navClick(idx, path) {
      if(path =="") {
        this.$message('设备没有对接');
      } else {
        this.currentPath = path;
        this.navIndex = idx;
        this.$router.push(path);
      }

    },
    reportRepair() {
      this.$message({
        message: "操作成功！",
        type: "success",
      });
    },
    region(){
      this.$router.push('/setting/region')
    },
    device(){
      this.$router.push('/setting/device')
    },
    timing(){
      this.$router.push('/setting/timing')
    },
    user(){
      this.$router.push('/setting/user')
    },
    password(){
      this.$router.push('/setting/password')
    },
    com(){
      this.$router.push('/setting/company')
    },
    screen(){
      this.$router.push('/setting/screen')
    },  
    logout() {
      localStorage.removeItem('token')
      this.$router.push('/login')
    }
  },
  watch: {
    $route(to, from) {
      //console.log(to.name);
      //console.log("-----");
      this.sepalists = to.matched
      this.currentRouteName = to.name
      this.currentPath = to.fullPath
    },
  },
};
</script>
<style scoped>
.headerContent {
  width: 100%;
  height: 100%;
  display: flex;
}

.left {
  width: 247px;
  height: 100%;
  background-color: #f4f7fc;
}

.left .logoGroup {
  width: 247px;
  height: 200px;
  background-color: #3168fc;
  text-align: center;
}

.logoGroup .logo {
  width: 100%;
  /* height: 88px; */
  padding-top: 19px;
  text-align: center;
}

.logoGroup .logo img {
  width: 83px;
  height: 83px;
}

.unitName {
  width: 100%;
}

.unitName img {
  margin-top: 18px;
  /* width: 100%; */
}

.unitName p {
  text-align: center;
  color: #ffffff;
  font-size: 26px;
  margin-top: 5px;
}

.right {
  width: calc(100% - 247px);
  height: 100%;
  background-color: #ecf0f8;
  position: relative;
  background-image: url("../assets/images/top.png");
  background-size: 100% 200px;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}

.nav {
  display: flex;
  width: calc(100% - 40px);
  height: 190px;
  margin-left: 20px;
  overflow: hidden;
  /* justify-content: space-between; */
}

.nav li {
  width: 9.0%;
  position: relative;
  /* height: 70px; */
  /* background-color: pink; */
}

.nav li > div {
  position: relative;
  left: 0;
  width: 100px;
  height: 100px;
  text-align: center;
  cursor: pointer;
}

.nav li > div .text {
  width: 100%;
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 9;
  color: #ffffff;
  text-align: center;
}

.nav li > div.active .text {
  color: #3168fc;
}

.nav li > div .text p {
  font-size: 18px;
  font-weight: normal;
}

.nav li > div img {
  position: absolute;
  z-index: -1px;
  left: calc(50% + 10px);
  top: 11px;
  height: 127px;
  transform: translateX(-50%);
  width: 22vw;
  /* display: none; */
  opacity: 0;
  transition: all 0.3s;
}

.nav li > div.active img {
  display: block;
  opacity: 1;
}

.nav li > div .text .iconfont {
  font-size: 36px;
  margin-bottom: 10px;
}

.breadcrumb {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 84px;
  background: #ffffff;
  border-radius: 20px;
}
.breadList {
  position: absolute;
  left: 130px;
  top: 35px;
  display: flex;
}
.breadList .el-icon-s-home {
  font-size: 18px;
  margin-right: 10px;
  color: #9DA7BC;
  vertical-align: middle;
  margin-top: -1px;
}
.breadcrumb.first {
  /* border-radius: 0 20px 20px 20px; */
}
.breadList .el-breadcrumb {
font-size: 16px;
color: #1B2F61;
}
.breadcrumb .leftArrow {
  position: absolute;
  left: 0;
  top: 0;
  width: 76px;
  height: 100%;
  text-align: center;
}
.leftArrow .iconfont,
.rightArrow .iconfont {
  font-size: 26px;
  cursor: pointer;
  margin-top: 23px;
  color: #7e8ba7;
}
.leftArrow .iconfont:hover,
.rightArrow .iconfont:hover {
  color: #3168fc;
}
.breadcrumb .leftArrow::after {
  position: absolute;
  content: "";
  right: -16px;
  top: 19px;
  height: 43px;
  width: 1px;
  background-color: #cdd5e6;
}
.breadcrumb .rightArrow::after {
  position: absolute;
  content: "";
  left: -16px;
  top: 19px;
  height: 43px;
  width: 1px;
  background-color: #cdd5e6;
}
.breadcrumb .rightArrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 76px;
  height: 100%;
  text-align: center;
}
#yjbx {
  width: 146px;
  height: 38px;
  line-height: 38px;
  background: linear-gradient(90deg, #fb312f 0%, #ff693b 99%);
  border-radius: 19px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  position: absolute;
  right: 110px;
  top: 20px;
  cursor: pointer;
  transition: all 0.3s;
}
#yjbx:hover {
  box-shadow: 0 0 3px #ff0000;
}
#more {
  position: absolute;
  right: 110px;
  top: 22px;
  font-size: 25px;
  color: #98A6C6;
  cursor: pointer;
}
.right .userInfo {
  position: absolute;
  right: 20px;
  top: 11px;
}
.userInfo .info {
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.userInfo .info img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.userInfo .info i {
  margin: 0 5px;
}
.infoBox li{
  line-height: 36px;
  color: #666;
  text-align: center;
}
.infoBox li a {
  color: #333333;
}

.infoBox li:hover a{
  color: #409eff;
}
</style>