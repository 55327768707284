<template>
  <div id="container">   
    <el-card class="box-card">
        <div class="container" style="width:500px">
        <el-form ref="uform" :rules="rules" :model="uform" label-width="70px">
            <el-form-item prop="oldpassword" label="旧密码" type="string">
            <el-input v-model="uform.oldpassword" type="password"></el-input>
            </el-form-item>
            <el-form-item prop="password" label="新密码" type="string">
            <el-input v-model="uform.password" type="password"></el-input>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="saveEdit('uform')">立即提交</el-button>
            <el-button>取消</el-button>
            </el-form-item>
        </el-form>
        </div>
   </el-card>
  </div>
</template>

<script>
import { fetchPost, fetchGet, fetchDelete } from "@/service/index";
export default {
  data() {
    return {
      roles: [],
      uform: {
        oldpassword: "",
        password: "",
        version: 0
      },
      rules: {
        oldpassword: [{ required: true, message: " ", trigger: "blur" }],
        password: [{ required: true, message: " ", trigger: "blur" }]
      }
    };
  },
  methods: {
    saveEdit(uform) {
      this.$refs[uform].validate(valid => {
        if (valid) {
          fetchPost("/api/base/user/password", this.uform)
            .then(res => {
              this.$message.success(res.msg);
            })
            .catch(err => {
              this.$message.success(`出错了`);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
#container{
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}


.box-card{
 padding-top: 15px;
}
</style>