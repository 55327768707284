
<template>
  <div id="disinfect">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "disinfect",

  data() {
    return {};
  },

  mounted() {},
  
  methods: {},
};
</script>

<style lang="css" scoped>
#disinfect {
  width: 100%;
  height: 100%;
}
</style>