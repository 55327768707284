<!--
 * @Author: ZS
 * @Date: 2022-11-16 10:17:59
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-18 16:46:11
 * @Description: 电源报警统计
-->
<template>
  <div id="alarmStatistics">
    <div class="row row1">
      <div class="boxTit">
        <span>用电报警数据趋势({{ boxTit }})</span>
        <div class="searchCondition">
          <div class="selectGroup">
            <el-select v-model="selectValue1" placeholder="请选择">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="selectValue2" placeholder="请选择">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="radioGroup">
            <el-radio-group v-model="timeRadio" @change="radioChange">
              <el-radio :label="'year'">年</el-radio>
              <el-radio :label="'month'">月</el-radio>
              <el-radio :label="'date'">日</el-radio>
            </el-radio-group>
          </div>
          <div class="timeGroup">
            <el-date-picker v-model="timeVal" :type="dateType" placeholder="请选择" :clearable="false">
            </el-date-picker>
          </div>
          <div class="btnGroup">
            <div class="searchBtn" @click="search()">查询</div>
          </div>
        </div>
      </div>
      <div id="bar1"></div>
    </div>
    <div class="row row2">
      <div class="boxTit">
        <span>实时报警列表</span>
        <div class="more">
          <img src="../../assets/images/power/more.png" alt="" />
        </div>
      </div>
      <div class="tableBox">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="xuhao" label="序号" width="100" align="center">
          </el-table-column>
          <el-table-column prop="address" label="地址" align="center">
          </el-table-column>
          <el-table-column prop="equipmentName" label="设备名称" align="center">
          </el-table-column>
          <el-table-column prop="line" label="线路" align="center">
          </el-table-column>
          <el-table-column prop="type" label="类型" align="center">
          </el-table-column>
          <el-table-column prop="alarmTime" label="报警时间" align="center">
          </el-table-column>
          <el-table-column prop="status" label="状态" width="150" align="center">
            <template slot-scope="scope">
              <div class="status1 status" v-show="scope.row.status == 1">未处理</div>
              <div class="status2 status" v-show="scope.row.status == 2">已处理</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        boxTit: "年",
        timeRadio: "year",
        dateType: "year",
        timeVal: "",
        options1: [{
            value: "1",
            label: "智慧校园1",
          },
          {
            value: "2",
            label: "智慧校园2",
          },
          {
            value: "3",
            label: "智慧校园3",
          },
        ],
        selectValue1: "1",
        selectValue2: "1",
        options2: [{
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
        ],
        bar1: null, //柱状图实例
        barData1: [{
            name: "漏电",
            value: 6,
          },
          {
            name: "过流",
            value: 14,
          },
          {
            name: "过温",
            value: 12,
          },
          {
            name: "过载",
            value: 4,
          },
          {
            name: "短路",
            value: 5,
          },
          {
            name: "过压",
            value: 8,
          },
          {
            name: "欠压",
            value: 9,
          },
        ],
        tableData: [{
          xuhao: 1,
          address: '智慧校园',
          equipmentName: 'B110105G2022-0145',
          line: '上子开关12',
          type: '漏电报警',
          alarmTime: '2022-10-23 12:30:23',
          status: 1
        }, {
          xuhao: 2,
          address: '智慧校园',
          equipmentName: 'B110105G2022-0145',
          line: '上子开关12',
          type: '漏电报警',
          alarmTime: '2022-10-23 12:30:23',
          status: 1
        }, {
          xuhao: 3,
          address: '智慧校园',
          equipmentName: 'B110105G2022-0145',
          line: '上子开关12',
          type: '漏电报警',
          alarmTime: '2022-10-23 12:30:23',
          status: 2
        }, {
          xuhao: 4,
          address: '智慧校园',
          equipmentName: 'B110105G2022-0145',
          line: '上子开关12',
          type: '漏电报警',
          alarmTime: '2022-10-23 12:30:23',
          status: 1
        }, {
          xuhao: 5,
          address: '智慧校园',
          equipmentName: 'B110105G2022-0145',
          line: '上子开关12',
          type: '漏电报警',
          alarmTime: '2022-10-23 12:30:23',
          status: 2
        }, ]
      };
    },
    mounted() {
      this.drawbar1();
    },
    methods: {
      search() {},
      warningTabChange(idx) {
        this.warningTabIndex = idx;
      },
      radioChange(param) {
        // console.log(param)
        this.timeVal = "";
        this.dateType = param;
        if (param == "year") {
          this.boxTit = "年";
        } else if (param == "month") {
          this.boxTit = "月";
        } else if (param == "date") {
          this.boxTit = "日";
        }
      },
      drawbar1() {
        this.bar1 = this.$echarts.init(document.getElementById("bar1"));
        let data1 = [],
          data2 = [],
          data3 = [];
        for (let i = 0; i < this.barData1.length; i++) {
          data1.push(this.barData1[i].name);
          data2.push(this.barData1[i].value);
        }
        var max = this.getArrMax(data2) + 1;
        for (let i = 0; i < this.barData1.length; i++) {
          data3.push(max);
        }
        let option = {
          title: {
            text: "单位：次",
            textStyle: {
              color: '#9DA1B0',
              fontWeight: 'normal',
              fontSize: '13'
            },
            left: '10',
            top: '0'
          },
          grid: {
            top: "45",
            left: "15",
            right: "0",
            bottom: "0",
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            textStyle: {
              color: "#ffffff",
            },
            backgroundColor: "rgba(50,50,50,0.6)",
            transitionDuration: 0,
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          xAxis: [

            {
              type: "category",
              data: data1,
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#666974",
                },
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                // 坐标轴刻度的相关设置
                show: false, // 不显示刻度
              },
              axisLine: {
                lineStyle: {
                  color: "#CED6E4",
                },
              },
              splitLine: {
                show: false,
              },
            },
            {
              type: "category",
              data: data1,
              axisLabel: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                // 坐标轴刻度的相关设置
                show: false, // 不显示刻度
              },
            },
          ],
          yAxis: {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666974",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#CED6E4",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#CED6E4",
                type: "dashed",
              },
            },
          },
          series: [{
              data: data2,
              type: "bar",
              xAxisIndex: 0,
              name: "报警数",
              barMaxWidth: 22,
              itemStyle: {
                barBorderRadius: [11, 11, 11, 11], // 圆角（左上、右上、右下、左下）
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [{
                      offset: 0,
                      color: "#59ABF1",
                    },
                    {
                      offset: 1,
                      color: "#5D8DFF",
                    },
                  ],
                  false
                ),
              },
              // showBackground: true,
              // backgroundStyle: {
              //   color: "rgba(237, 241, 248,.5)",
              //   borderWidth:10
              // },
            },
            {
              //柱状图背景
              data: data3,
              type: "bar",
              xAxisIndex: 1,
              name: "报警数",
              barMaxWidth: 150,
              barCategoryGap: "60%",
              itemStyle: {
                normal: {
                  color: "rgba(206, 214, 228,.3)"
                },
              },
              tooltip: {
                trigger: "item",
                show: false,
              },
            },
          ],
        };
        this.bar1.setOption(option);
        window.addEventListener("resize", function () {
          this.bar1.resize();
        });
      },
      //数组最大值
      getArrMax(arr) {
        var max = arr[0];

        for (var i = 1; i < arr.length; i++) {
          if (max < arr[i]) {
            max = arr[i];
          }
        }

        return max;
      },
    },
  };
</script>
<style scoped>
  #alarmStatistics {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 0 25px 0;
    height: 100%;
  }

  .row {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0 20px;
    width: 100%;
    position: relative;
  }

  .row1 {
    /* height: calc((100% - 20px) * 0.5323); */
    height: calc(100% - 367px);
    margin-bottom: 20px;
  }

  .row2 {
    /* height: calc((100% - 20px) * 0.4677); */
    height: 347px;
  }

  .boxTit {
    position: relative;
    height: 58px;
    display: flex;
    /* padding: 0 20px; */
    width: 100%;
    justify-content: space-between;
  }

  .boxTit span {
    line-height: 58px;
    color: rgba(27, 47, 97, 1);
    font-size: 22px;
    padding-left: 10px;
  }

  .boxTit .searchCondition {
    display: flex;
    /* padding-top: 15px; */
  }

  .selectGroup {
    padding-top: 12px;
  }

  .selectGroup .el-select {
    margin-right: 15px;
    width: 149px;
  }

  .boxTit .searchCondition .radioGroup {
    margin-right: 20px;
    padding-top: 17px;
  }

  .boxTit .searchCondition .timeGroup {
    padding-top: 12px;
  }

  .btnGroup {
    padding-top: 12px;
    padding-left: 20px;
  }

  /* 单选框选中后的字体颜色 */
  .boxTit /deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #464852 !important;
  }

  /* 单选框选中后小圆点的颜色 */
  .boxTit /deep/ .el-radio__input.is-checked .el-radio__inner {
    background: #235cfb !important;
    border-color: #235cfb !important;
  }

  .boxTit /deep/ .el-radio__inner {
    width: 18px;
    height: 18px;
  }

  .boxTit /deep/ .el-radio__inner::after {
    width: 8px;
    height: 8px;
  }

  .boxTit /deep/ .el-radio__label {
    font-size: 16px;
    color: #464852;
    line-height: 20px;
  }

  .boxTit /deep/ .el-radio {
    margin-right: 10px;
  }

  .boxTit /deep/ .el-date-editor.el-input {
    width: 109px;
    height: 32px;
  }

  .boxTit /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 0;
    border: 1px solid #ced6e4;
    padding-right: 5px;
  }

  .boxTit /deep/ .el-input__icon {
    line-height: 32px;
    color: #5d85f3;
    font-size: 16px;
  }

  .searchBtn {
    width: 101px;
    height: 32px;
    line-height: 32px;
    background: linear-gradient(180deg, #509cff 0%, #3c8cfe 100%);
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
  }

  .searchBtn:hover {
    background: linear-gradient(180deg, #3f8ef5 0%, #1d75f0 100%);
  }

  #bar1 {
    width: 100%;
    height: calc(100% - 58px);
  }

  .more {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 38px;
    top: 18px;
    cursor: pointer;
    z-index: 9;
  }

  .tableBox {
    margin-top: 5px;
  }

  .tableBox /deep/ .el-table .el-table__cell {
    padding: 8px 0;
    border-bottom: 1px solid #EAEDF2;
  }

  .tableBox /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: rgba(17, 104, 218, .05);
  }

  .tableBox /deep/.el-table thead th {
    background: rgba(80, 168, 255, 0.1);
    border-top: 2px solid #50A8FF;
  }

  .tableBox .status {
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 13px;
    color: #ffffff;
    margin: 0 auto;
  }

  .tableBox .status.status1 {
    background-color: #FFA32B;
  }

  .tableBox .status.status2 {
    background-color: #03B86D;

  }
</style>