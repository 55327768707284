<!--
 * @Author: ZS
 * @Date: 2022-11-16 10:38:13
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-16 10:38:13
 * @Description: 用电报告
-->
<template>
  <div id="powerUseReport">
    <div class="row">
      <div class="boxTit">
        <span>电量使用报告</span>
        <div class="searchCondition">
          <div class="selectGroup">
            <el-select v-model="selectValue1" placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select v-model="selectValue2" placeholder="请选择">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="timeGroup">
            <el-date-picker
              v-model="timeVal"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </div>
          <div class="btnGroup">
            <div class="searchBtn" @click="search()">查询</div>
          </div>
        </div>
      </div>
      <div class="chartBox">
        <div class="tableBox">
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column
              prop="xuhao"
              label="序号"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="position" label="地址" align="center">
            </el-table-column>
            <el-table-column prop="boxName" label="电箱名称" align="center">
            </el-table-column>
            <el-table-column prop="lineName" label="线路名称" align="center">
            </el-table-column>
            <el-table-column prop="lineType" label="线路类型" align="center">
            </el-table-column>
            <el-table-column prop="powerNum" label="电量" align="center">
            </el-table-column>
            <el-table-column prop="sTime" label="开始时间" align="center">
            </el-table-column>
            <el-table-column prop="eTime" label="结束时间" align="center">
            </el-table-column>
          </el-table>
        </div>
        <div class="fenye">
          <el-pagination
  background
  layout="prev, pager, next"
  :total="1000">
</el-pagination>
</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timeVal: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options1: [
        {
          value: "1",
          label: "智慧校园1",
        },
        {
          value: "2",
          label: "智慧校园2",
        },
        {
          value: "3",
          label: "智慧校园3",
        },
      ],
      selectValue1: "1",
      selectValue2: "1",
      options2: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
      ],
      tableData: [
        {
          xuhao: 1,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },
        {
          xuhao: 2,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },{
          xuhao: 3,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },{
          xuhao: 4,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },{
          xuhao: 5,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },{
          xuhao: 6,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },{
          xuhao: 7,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },{
          xuhao: 8,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },{
          xuhao: 9,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },{
          xuhao: 10,
          position: "智慧校园",
          boxName: "B110105G2022-0145",
          lineName: "上子开关12",
          lineType: "其它",
          powerNum: "14.2lw",
          sTime: "2022-10-23 12:30:23",
          eTime: "2022-11-20 12:30:23",
          status: 1,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
#powerUseReport {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px 0 25px 0;
  height: 100%;
}
.row {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
}

.boxTit {
  position: relative;
  height: 88px;
  display: flex;
  padding: 15px 20px;
  width: 100%;
  justify-content: space-between;
}

.boxTit span {
  line-height: 58px;
  color: rgba(27, 47, 97, 1);
  font-size: 22px;
  padding-left: 10px;
}

.boxTit .searchCondition {
  display: flex;
  /* padding-top: 15px; */
}

.boxTit .searchCondition .timeGroup {
  padding-top: 17px;
}

.btnGroup {
  padding-top: 17px;
  padding-left: 20px;
}
.selectGroup {
  padding-top: 17px;
}

.selectGroup .el-select {
  margin-right: 15px;
  width: 149px;
}
.boxTit /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  border-radius: 0;
  border: 1px solid #ced6e4;
  padding-right: 5px;
}
.boxTit /deep/ .el-date-editor .el-range-separator {
  line-height: 25px;
}
.boxTit /deep/ .el-input__icon {
  line-height: 32px;
  color: #5d85f3;
  font-size: 16px;
}

.searchBtn {
  width: 101px;
  height: 32px;
  line-height: 32px;
  background: linear-gradient(180deg, #509cff 0%, #3c8cfe 100%);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}

.searchBtn:hover {
  background: linear-gradient(180deg, #3f8ef5 0%, #1d75f0 100%);
}
.boxTit /deep/ .el-date-editor.el-input {
  width: 209px;
  height: 32px;
}

.boxTit /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  border-radius: 0;
  border: 1px solid #ced6e4;
  padding-right: 5px;
}

.boxTit /deep/ .el-input__icon {
  line-height: 25px;
  color: #5d85f3;
  font-size: 16px;
}
.chartBox {
  width: 100%;
  height: calc(100% - 88px);
}


.tableBox {
   width: 100%;
   padding: 0 20px 0 25px;
  }

  .tableBox /deep/ .el-table .el-table__cell {
    padding: 8px 0;
    border-bottom: 1px solid #EAEDF2;
  }

  .tableBox /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: rgba(17, 104, 218, .05);
  }

  .tableBox /deep/.el-table thead th {
    background: rgba(80, 168, 255, 0.1);
    border-top: 2px solid #50A8FF;
  }

  .tableBox .status {
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 13px;
    color: #ffffff;
    margin: 0 auto;
  }

  .tableBox .status.status1 {
    background-color: #FFA32B;
  }

  .tableBox .status.status2 {
    background-color: #03B86D;

  }
  .fenye {
    padding: 20px 20px 20px 25px;
  }
</style>