import axios from 'axios';

import { Message } from 'element-ui';

const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    publicPath: '/',
    timeout: 60000,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' },
})

service.interceptors.request.use(config => {
    // 这里的config包含每次请求的内容
    let token = window.sessionStorage.getItem('token');
    if(!token){
        token=localStorage.getItem('token');
    }
    if (token) {
        // 添加headers
        config.headers.token = `${token}`;
        //config.headers['content-type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    } else { }
    return config;
}, error => {
    console.log(error);
    return Promise.reject();
})

service.interceptors.response.use(response => {
    if (response.status === 200) {
        return response.data;
    } else if (response.status == 401) {
        console.log("凭证过期 重新登录!");        
    } else {
        Promise.reject();
    }
}, error => {    
    return Promise.reject();
})

export default service;