<!--
 * @Author: ZS
 * @Date: 2022-11-16 10:30:52
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-16 10:30:52
 * @Description: 用电排行
-->
<template>
  <div id="powerUseRank">
    <div class="row">
      <div class="boxTit">
        <span>电量使用排行</span>
        <div class="searchCondition">
          <div class="timeGroup">
            <el-date-picker
              v-model="timeVal"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </div>
          <div class="btnGroup">
            <div class="searchBtn" @click="search()">查询</div>
          </div>
        </div>
      </div>
      <div class="chartBox" id="bar">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timeVal: "",
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        xValue: [100,190,200,250,288,350,368,500],
        yValue: ['教师', '会议室', '功能室', '图书馆', '餐厅', '宿舍','操场', '绿化'],
    };
  },
  mounted() { this.show()},
  methods: {
    show() {
        this.charts = this.$echarts.init(document.getElementById('bar'))
        var option = {
          tooltip: {
            show: true
          },
          title: {
            text: "单位：kw",
            textStyle: {
              color: '#9DA1B0',
              fontWeight: 'normal',
              fontSize: '15'
            },
            left: '45',
            top: '0'
          },
          grid: {
            top: "45",
            left: "45",
            right: "45",
            bottom: "45",
            containLabel: true,
          },
          yAxis: {
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              inside: true,
              verticalAlign: 'bottom',
              lineHeight: 40,
              color: '#666974',
              formatter: function (value, index) {   // 设置y轴文字的颜色
                if (index > 3) {
                  return '{first|' + value + '}'
                } else {
                  return '{other|' + value + '}'
                }
              },
              rich: {
                other: {
                  color: '#666974',
                  opacity: 1,
                  fontSize:16
                },
                first: {
                  color: '#666974',
                  opacity: 1,
                  fontSize:16
                }
              }
            },
            data: this.yValue
          },
          xAxis: {
            nameTextStyle: {
              color: '#666974',
              align: 'right'
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              color: '#666974'
            },
          },
          series: [{
            name: '用电排行',
            barWidth: 15,
            type: 'bar',
            data: this.xValue,
            itemStyle: {
              normal: {
                borderRadius: [20, 20, 20, 20],
                 color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [{
                      offset: 0,
                      color: "#59ABF1",
                    },
                    {
                      offset: 1,
                      color: "#5D8DFF",
                    },
                  ],
                  false
                ),
              },
            }
          }]
        };
        // 使用刚指定的配置项和数据显示图表。
        this.charts.setOption(option);
        window.addEventListener('resize', () => {
          this.charts.resize()
        })
      }
  },
};
</script>
<style scoped>
#powerUseRank {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px 0 25px 0;
  height: 100%;
}
.row {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
}

.boxTit {
  position: relative;
  height: 88px;
  display: flex;
  padding: 15px 20px;
  width: 100%;
  justify-content: space-between;
}

.boxTit span {
  line-height: 58px;
  color: rgba(27, 47, 97, 1);
  font-size: 22px;
  padding-left: 10px;
}

.boxTit .searchCondition {
  display: flex;
  /* padding-top: 15px; */
}

.boxTit .searchCondition .timeGroup {
  padding-top: 17px;
}

.btnGroup {
  padding-top: 17px;
  padding-left: 20px;
}

.boxTit /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  border-radius: 0;
  border: 1px solid #ced6e4;
  padding-right: 5px;
}
.boxTit /deep/ .el-date-editor .el-range-separator {
  line-height: 25px;
}
.boxTit /deep/ .el-input__icon {
  line-height: 32px;
  color: #5d85f3;
  font-size: 16px;
}

.searchBtn {
  width: 101px;
  height: 32px;
  line-height: 32px;
  background: linear-gradient(180deg, #509cff 0%, #3c8cfe 100%);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}

.searchBtn:hover {
  background: linear-gradient(180deg, #3f8ef5 0%, #1d75f0 100%);
}
.boxTit /deep/ .el-date-editor.el-input {
  width: 209px;
  height: 32px;
}

.boxTit /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  border-radius: 0;
  border: 1px solid #ced6e4;
  padding-right: 5px;
}

.boxTit /deep/ .el-input__icon {
  line-height: 25px;
  color: #5d85f3;
  font-size: 16px;
}
.chartBox {
  width: 100%;
  height: calc(100% - 88px);
}
</style>