<!--
 * @Author: ZS
 * @Date: 2022-11-16 10:42:35
 * @LastEditors: ZS
 * @LastEditTime: 2022-11-18 16:11:16
 * @Description: 电量数据
-->
<template>
  <div id="powerData">
    <div class="row row1">
      <div class="boxTit">
        <span>月总用电量</span>
        <div class="searchCondition">
          <div class="timeGroup">
            <el-date-picker v-model="timeVal" :type="dateType" placeholder="请选择" :clearable="false">
            </el-date-picker>
          </div>

        </div>
      </div>
      <p class="unit">单位:KW/h</p>
      <div class="boxContent">
        <div class="numBox numBox1">
          <ul class="chartNum">
            <li v-for="(item,index) in totalNumList" :key="index" :class="{active:item.hasVal}">
              <countTo :startVal='0' :endVal='item.value' :duration='2000'></countTo>
            </li>
          </ul>
        </div>
        <div class="numBox numBox2">
          <ul class="numGather">
            <li>
              <p>4365</p>
              <h5>年总电量</h5>
            </li>
            <li>
              <p>656</p>
              <h5>上月总电量</h5>
            </li>
            <!-- <li class="up">
          <p>80.9%
         <img src="../../assets/images/power/up.png" alt="">
        <h5>月度环比</h5>
      </li> -->
            <li class="down">
              <p>80.9%
                <img src="../../assets/images/power/down.png" alt=""></p>
              <h5>月度环比</h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row row2">
      <div class="boxTit">
        <span>用电详情</span>
      </div>
      <div class="pieBox">
        <ul class="pieList">
          <li>
            <img src="../../assets/images/power/cir_1.png" alt="">
            <b>3.8</b>
            <p>月平均用电</p>
          </li>
          <li>
            <img src="../../assets/images/power/cir_2.png" alt="">
            <b>1.19</b>
            <p>日平均用电</p>
          </li>
          <li>
            <img src="../../assets/images/power/cir_3.png" alt="">
            <b>6.38</b>
            <p>最高月用电量</p>
          </li>
          <li>
            <img src="../../assets/images/power/cir_4.png" alt="">
            <b>1.22</b>
            <p>最高日用电量</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import countTo from "vue-count-to";
  export default {
    components: {
      countTo,
    },
    data() {
      return {
        dateType: "month",
        timeVal: '',
        totalNumList: null,
        totalNum: 2298 //须为整数
      };
    },
    mounted() {
      this.splitNumber()
    },
    methods: {
      // 分割数字
      splitNumber() {
        let temArr;
        let numArr = [];
        temArr = (this.totalNum + '').split('')
        for (let i = 0; i < temArr.length; i++) {
          numArr.push({
            value: temArr[i] - 0,
            hasVal: true
          })

        }
        let len = 7 - temArr.length;
        for (let j = 0; j < len; j++) {
          numArr.unshift({
            value: 0,
            hasVal: false
          })

        }
        this.totalNumList = numArr
        // console.log(numArr)
      }
    }
  }
</script>
<style scoped>
  #powerData {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 0 25px 0;
    height: 100%;
  }

  .row {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0 20px;
    width: 100%;
    position: relative;
  }

  .row1 {
    /* height: calc((100% - 20px) * 0.5323); */
    height: 298px;
    margin-bottom: 20px;
  }

  .row2 {
    /* height: calc((100% - 20px) * 0.4677); */
    height: calc(100% - 318px);
  }

  .boxTit {
    position: relative;
    height: 58px;
    display: flex;
    /* padding: 0 20px; */
    width: 100%;
    justify-content: space-between;
  }

  .boxTit span {
    line-height: 58px;
    color: rgba(27, 47, 97, 1);
    font-size: 22px;
    padding-left: 15px;
  }

  .boxTit .searchCondition {
    display: flex;
    /* padding-top: 15px; */
  }

  .boxTit /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 0;
    border: 1px solid #ced6e4;
    padding-right: 5px;
  }

  .boxTit /deep/ .el-input__icon {
    line-height: 32px;
    color: #5d85f3;
    font-size: 16px;
  }

  .boxTit .searchCondition .timeGroup {
    padding-top: 12px;
  }

  .boxTit /deep/ .el-date-editor.el-input {
    width: 179px;
    height: 32px;
  }

  .boxTit /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 0;
    border: 1px solid #ced6e4;
    padding-right: 5px;
  }

  p.unit {
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    padding-left: 15px;
  }

  .boxContent {
    width: 100%;
    height: calc(100% - 98px);
    /* background-color: pink; */
    display: flex;
  }

  .boxContent .numBox {
    height: 127px;
  }

  .boxContent .numBox1 {
    padding: 46px 100px 0 15px;
    border-right: 1px solid #eaeaea;
  }

  .boxContent .numBox2 {
    padding: 22px 0px 0 20px;
    width: calc(100% - 710px);
  }

  .chartNum {
    display: flex;
    width: 589px;
    justify-content: space-between;
  }

  .chartNum li {
    width: 65px;
    height: 77px;
    background-image: url("../../assets/images/power/numBg.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    border-radius: 10px;
    line-height: 77px;
    text-align: center;
    color: #A4B9ED;
    font-weight: 700;
    font-size: 40px;
  }

  li.active {
    color: #2B67FC;
  }

  .numGather {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .numGather li {
    text-align: center;
  }

  .numGather li h5 {
    width: 185px;
    height: 39px;
    background: #F2F5FA;
    border-radius: 2px;
    line-height: 39px;
    text-align: center;
    color: #464852;
    font-size: 20px;
    margin-top: 10px;
  }

  .numGather li p {
    color: #414654;
    font-weight: 700;
    font-size: 38px;
    /* display: flex; */
    width: 100%;
  }

  .numGather li.down p {
    color: #1BC179;
  }

  .numGather li.up p {
    color: #FC3E32;
  }

  .pieBox {
    width: 100%;
    height: calc(100% - 58px);
    padding: 0 30px;
    /* background-color: pink; */
    position: relative;
  }

  .pieList {
    width: calc(100% - 110px);
    height: 230px;
    position: absolute;
    left: 55px;
    top: 50%;
    transform: translateY(-50%);
    /* background-color: palegoldenrod; */
    display: flex;
    justify-content: space-between;
  }

  .pieList li {
    width: 230px;
    height: 230px;
    /* background-color: pink; */
    position: relative;
    text-align: center;
    padding-top: 60px;
  }

  .pieList li img {
    width: 230px;
    height: 230px;
    position: absolute;
    left: 0;
    top: 0;
    animation: cirRotate1 20s infinite linear;
  }

  .pieList li p {
    font-size: 20px;
    line-height: 30px;
  }

  .pieList li b {
    font-size: 52px;
    /* margin-bottom: 10px; */
    padding-bottom: 10px;
  }

  .pieList li:first-child b {
    color: #2B67FC;
  }

  .pieList li:nth-child(2) b {
    color: #FFA31F;
  }

  .pieList li:nth-child(3) b {
    color: #1BC179;
  }

  .pieList li:nth-child(4) b {
    color: #FE653C;
  }

  @keyframes cirRotate1 {
    from {

      transform: translate(0, 0) rotate(0deg);
    }

    to {
      transform: translate(0, 0) rotate(359deg);
    }
  }
</style>