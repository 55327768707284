<template>
    <div id="light">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'Light',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="css" scoped>
#light {
    width: 100%;
    height: 100%;
}
</style>