<template>
  <div id="container">
    <el-card class="box-card">
    <div class="container">
      <div class="handle-box">
         <el-button
          type="danger"
          icon="el-icon-plus"
          class="mr5"
          @click="handleCreate()"
        >新增设备</el-button>     
        <el-button
          type="primary"
          icon="el-icon-edit"
          class="mr5"
          @click="handleVersion()"
        >设备版本</el-button>  
        <el-button
          type="danger"
          icon="el-icon-refresh-right"
          class="mr5"
          @click="handleReboot()"
        >重启时间</el-button> 
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="mr10"
          @click="handleSetting()"
        >联动设置</el-button>        
        <el-cascader           
            clearable          
            :options="regions"   
            :show-all-levels="true"
            :props="{value:'id',label:'name',children:'children',checkStrictly: false, emitPath:false, }"
            v-model="query.region_id"            
            placeholder="请选择单元"
            class="handle-select mr10"                     
        ></el-cascader>
        <!--el-input v-model="queryName" placeholder="单元" class="handle-input mr10"></el-input-->
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%;margin-bottom: 20px;"
        :row-key="handlerRowKeys"
        stripe
        @expand-change="handleExpandChange"   
        border
        :highlight-current-row="true"        
      >        
        <el-table-column type="expand" width="50">
          <template slot-scope="props">  
            <div class="block" style="margin-top:10px;">
              <el-card>
                <div slot="header" class="clearfix">
                  <span>设备状态</span>
                </div>
                <el-table
                  :data="props.row.device_status"
                  ref="multipleTable"
                  border
                  class="table"                
                  header-cell-class-name="table-header"
                >
                  <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                  <el-table-column prop="deviceId" label="ID"  align="center"></el-table-column>  
                  <el-table-column prop="deviceType" label="类型" align="center"></el-table-column> 
                  <el-table-column prop="deviceName" label="名称" align="center"></el-table-column> 
                  <el-table-column prop="status" label="状态" align="center">
                    <template slot-scope="scope">
                       <el-tag type="success" v-show="(scope.row.status==0)">关</el-tag>
                       <el-tag type="danger" v-show="(scope.row.status==1)">开</el-tag>
                    </template>
                  </el-table-column>                  
                  <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        icon="el-icon-delete"
                        size="small "
                        @click="handleStatusDel(props.row,scope.row)"
                      >删除</el-button>
                    </template>
                  </el-table-column>             
                </el-table>
              </el-card>
            </div>  
            <!--div class="block">
              <el-card>
                <div slot="header" class="clearfix">
                  <span>设备列表</span>
                </div>
                <el-table
                  :data="props.row.sub_device"
                  border
                  class="table"
                  ref="multipleTable"
                  header-cell-class-name="table-header"
                >
                  <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>                 
                  <el-table-column prop="deviceName" label="名称"  width="120" align="center"></el-table-column>
                  <el-table-column prop="deviceId" label="ID"  width="120" align="center"></el-table-column>  
                  <el-table-column prop="deviceType" label="类型"  width="120" align="center"></el-table-column> 
                  <el-table-column prop="" label="备注"  align="center"></el-table-column>                
                </el-table>
              </el-card>
            </div--> 
          </template>
        </el-table-column>
        <el-table-column type="selection" width="55" align="center"></el-table-column>        
        <el-table-column prop="name" label="名称" align="center"></el-table-column>    
        <el-table-column prop="room_id" label="班级编号" align="center"></el-table-column>          
        <el-table-column prop="client_id" label="登录ID" width="150" align="center"></el-table-column>       
        <el-table-column prop="kind" label="型号" width="100" align="center"></el-table-column> 
        <el-table-column prop="light_no" label="灯光数量" width="100" align="center"></el-table-column>
        <el-table-column prop="disinfect_no" label="消毒数量" width="100" align="center"></el-table-column>
        <el-table-column prop="power_no" label="电源数量" width="100" align="center"></el-table-column>
        <el-table-column prop="ac_no" label="空调数量" width="100" align="center"></el-table-column>
        <el-table-column prop="curtain_no" label="窗帘数量" width="100" align="center"></el-table-column>
        <el-table-column label="状态" align="center"  width="100"  :show-overflow-tooltip="true">
          <template slot-scope="scope">            
            <el-tag type="success" v-show="(scope.row.status==0)">未安装</el-tag>
            <el-tag type="success" v-show="(scope.row.status==1)">在线</el-tag>
            <el-tag type="success" v-show="(scope.row.status==2)">离线</el-tag>
         </template>
        </el-table-column>   
        <!--el-table-column prop="schemas" label="模式值"  width="80"  align="center"></el-table-column> 
        <el-table-column label="模式管理" align="center"  width="100">
          <template slot-scope="scope">
            <el-tag  v-show="(scope.row.schemas==0)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'warning':'danger'">没有设定</el-tag>
            <el-tag  v-show="(scope.row.schemas==1)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">上课模式</el-tag>
            <el-tag  v-show="(scope.row.schemas==2)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">放学场景</el-tag>
            <el-tag  v-show="(scope.row.schemas==3)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">自习场景</el-tag>
            <el-tag  v-show="(scope.row.schemas==4)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">投影场景</el-tag>
            <el-tag  v-show="(scope.row.schemas==5)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">自动模式</el-tag>
            <el-tag  v-show="(scope.row.schemas==6)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">考试模式</el-tag>
            <el-tag  v-show="(scope.row.schemas==7)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">窗帘打开</el-tag>
            <el-tag  v-show="(scope.row.schemas==8)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">窗帘关闭</el-tag>
            <el-tag  v-show="(scope.row.schemas==9)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">黑板灯开</el-tag>
            <el-tag  v-show="(scope.row.schemas==10)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">黑板灯关</el-tag>
            <el-tag  v-show="(scope.row.schemas==11)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">教室灯调亮</el-tag>
            <el-tag  v-show="(scope.row.schemas==12)" @click="setSchema(scope.$index, scope.row)" :type="scope.row.min==1?'success':'danger'">教室灯调暗</el-tag>            
          </template>
        </el-table-column-->  
        <el-table-column prop="orders" label="排序" width="100" align="center"></el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              size="small"
              type="success"
              icon="el-icon-search"
              @click="handleDevice(scope.$index, scope.row)"
            >设备</el-button>  
            <el-button
              size="small"
              type="danger"
              icon="el-icon-top"
              @click="handleUpgrade(scope.$index, scope.row)"
            >升级</el-button>                                         
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
          <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="query.page_index"
              :page-size="query.page_size"
              :total="pageTotal"
              @current-change="handlePageChange"
          ></el-pagination>
      </div>
    </div>
    </el-card>

     <!-- 编辑弹出框 -->
    <el-dialog :title="title" :visible.sync="editVisible" width="35%">
      <el-form ref="uform" :rules="rules" :model="uform" label-width="80px">  
        <el-form-item prop="name" label="名称">
          <el-input v-model="uform.name"></el-input>
        </el-form-item> 
        <!--el-form-item prop="device_id" label="设备ID">
          <el-input v-model="uform.device_id"></el-input>
        </el-form-item-->      
        <el-form-item prop="region_id" label="归属">
          <el-cascader
            size="small"
            :options="regions"   
            :show-all-levels="true"
            :props="{value:'id',label:'name',children:'children',checkStrictly: false, emitPath:false}"
            v-model="uform.region_id"            
            placeholder="请选择单元"
            class="handle-del mr10"            
        ></el-cascader>    
        </el-form-item>            
        <el-form-item prop="room_id" label="班级编号">
          <el-input v-model="uform.room_id"></el-input>
        </el-form-item> 
        <el-form-item prop="password" label="密码">
          <el-input v-model="uform.password"></el-input>
        </el-form-item>   
        <el-form-item prop="kind" label="类型">
          <el-select
            clearable
            v-model="uform.kind"
            placeholder="选择类型"
            class="handle-select"           
          >
            <el-option label="简易型" :value="1"></el-option>
            <el-option label="大屏型" :value="2"></el-option>            
          </el-select>
        </el-form-item>   
        <el-form-item prop="light_no" label="灯光数量">
          <el-input v-model="uform.light_no"></el-input>
        </el-form-item>  
        <el-form-item prop="disinfect_no" label="消毒数量">
          <el-input v-model="uform.disinfect_no"></el-input>
        </el-form-item>  
        <el-form-item prop="power_no" label="电源数量">
          <el-input v-model="uform.power_no"></el-input>
        </el-form-item>   
        <el-form-item prop="ac_no" label="空调数量">
          <el-input v-model="uform.ac_no"></el-input>
        </el-form-item> 
        <el-form-item prop="curtain_no" label="窗帘数量">
          <el-input v-model="uform.curtain_no"></el-input>
        </el-form-item>   
        <el-form-item prop="orders" label="序号">
          <el-input v-model="uform.orders"></el-input>
        </el-form-item>  
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit('uform')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 版本弹出框 -->
    <el-dialog title="版本设置" :visible.sync="versionVisible" width="35%">
      <el-form ref="vform" :rules="vrules" :model="vform" label-width="80px">  
        <el-form-item prop="no" label="版本号">
          <el-input v-model="vform.no"></el-input>
        </el-form-item>         
        <el-form-item prop="url" label="升级地址">
          <el-input v-model="vform.url"></el-input>
        </el-form-item>   
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="versionVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveVersionEdit('vform')">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 版本弹出框 -->
    <el-dialog title="重启时间" :visible.sync="rebootVisible" width="35%">
      <el-form ref="bform" :rules="brules" :model="bform" label-width="80px">  
        <el-form-item prop="time" label="时间">         
          <el-time-select
            v-model="bform.time"
            :picker-options="{
              start: '06:30',
              step: '00:15',
              end: '23:30'
            }"
            placeholder="选择时间">
          </el-time-select>
        </el-form-item>  
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rebootVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRebootEdit('bform')">确 定</el-button>
      </span>
    </el-dialog>

    <!--模式设置-->
    <el-dialog title="模式设置" :visible.sync="modelVisible" width="35%">
      <el-form ref="sceneForm" :model="sceneForm" label-width="80px">  
        <el-form-item prop="name" label="模式">
          <el-select
            clearable
            v-model="sceneForm.deviceId"
            placeholder="选择模式"
            class="handle-select"           
          >
            <el-option label="上课模式" :value="1"></el-option>
            <el-option label="放学模式" :value="2"></el-option>
            <el-option label="自动模式" :value="3"></el-option>
            <el-option label="投影模式" :value="4"></el-option>
            <el-option label="自习模式" :value="5"></el-option>
            <el-option label="考试模式" :value="6"></el-option>
          </el-select>
        </el-form-item>       
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modelVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeModelEdit('sceneForm')">确 定</el-button>
      </span>
    </el-dialog>


    <!--普通灯设置-->
    <el-dialog title="普通灯设置" :visible.sync="lightVisible" width="35%">
      <el-form ref="lightForm" :model="lightForm" label-width="80px">  
        <el-form-item prop="name" label="名称">
          <el-select
            clearable
            v-model="lightForm.status"
            placeholder="选择开关"
            class="handle-select"           
          >
            <el-option label="开" :value="1"></el-option>
            <el-option label="关" :value="0"></el-option>            
          </el-select>
        </el-form-item>       
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="lightVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeLightEdit('lightForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!--联动设置-->
    <el-dialog title="联动设置" :visible.sync="settingVisible" width="35%">
      <el-form ref="settingForm" :model="settingForm" label-width="120px">  
        <el-form-item prop="mode" label="运行模式">
          <el-select
            clearable
            v-model="settingForm.mode"
            placeholder="选择模式"
            class="handle-select"           
          >
            <el-option label="上课" :value="1"></el-option>
            <el-option label="放学" :value="2"></el-option>            
          </el-select>
        </el-form-item>     
        <el-form-item prop="related" label="是否联动">
          <el-switch
            v-model="settingForm.related">
          </el-switch>
        </el-form-item>  
        <el-form-item prop="status" label="运行转速">
          <el-select
            clearable
            v-model="settingForm.status"
            placeholder="选择转速"
            class="handle-select"           
          >
            <el-option label="300" :value="300"></el-option>
            <el-option label="400" :value="400"></el-option>  
            <el-option label="500" :value="500"></el-option>
            <el-option label="600" :value="600"></el-option>
            <el-option label="700" :value="700"></el-option>
            <el-option label="800" :value="800"></el-option>
            <el-option label="900" :value="900"></el-option>
            <el-option label="1000" :value="1000"></el-option>
            <el-option label="1100" :value="1100"></el-option>
            <el-option label="1200" :value="1200"></el-option>          
          </el-select>
        </el-form-item>  
        <el-form-item prop="duration" label="运行时长">
          <el-select
            clearable
            v-model="settingForm.duration"
            placeholder="选择转速"
            class="handle-select"           
          >
            <el-option label="30分钟" :value="30"></el-option>           
            <el-option label="60分钟" :value="60"></el-option>
            <el-option label="90分钟" :value="90"></el-option>
            <el-option label="120分钟" :value="120"></el-option>
            <el-option label="150分钟" :value="150"></el-option>   
          </el-select>
        </el-form-item> 
        
        <el-form-item prop="startTime" label="有效开始时间">
          <el-time-select
                v-model="settingForm.startTime"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30'
                }"
                placeholder="选择时间">
              </el-time-select>
        </el-form-item> 

        <el-form-item prop="endTime" label="有效结束时间">
          <el-time-select
                v-model="settingForm.endTime"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30'
                }"
                placeholder="选择时间">
              </el-time-select>
        </el-form-item> 


      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeSettingEdit('settingForm')">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { fetchPost, fetchGet, fetchDelete } from "@/service/index";


export default {
  data() {
    return {
      tableData: [],
      query:{
        region_id:"",         
        page_index: 1,
        page_size: 10,    
      },
      companyId: localStorage.getItem('company_id'),
      versionVisible: false,  
      editVisible: false,
      modelVisible:false,  
      lightVisible:false,
      rebootVisible:false,
      settingVisible:false,
      multipleSelection: [],
      title: "",         
      companys:[],
      expands:[], 
      expended:{},  
      regions:[],
      pageTotal: 0,  
      propsRegion:{
        value:"id",
        label:"name",
        children:"children",
        checkStrictly: true,
        expandTrigger:"click",
        emitPath:false, 
      },    
      settingForm:{
        mode:1,
        related:1,
        duration:30,
        status:400,
        startTime:"08:00",
        endTime:"18:00",
        topic:"",        
      },
      vform:{
        id:1,
        no:"1.0.1",
        url:"http://"
      },
      vrules: {
        id:[{ required: true, message: " ", trigger: "blur" }], 
        no:[{ required: true, message: " ", trigger: "blur" }],   
        url:[{ required: true, message: " ", trigger: "blur" }],     
      },
      bform:{        
        time:"4:30"
      },
      brules: {       
        time:[{ required: true, message: " ", trigger: "blur" }],     
      },
      uform: {      
        name: "",
        company_id:0,
        superior_id: 0,
        region_id: 0,
        device_id:"",
        room_id:"",
        name:"",
        topic:"",
        device_status:[],
        sub_device:[],
        password:"",
        kind:2,
        light_no:0,
        power_no:0,
        disinfect_no:0,
        ac_no:0,
        curtain_no:0,
        orders: 1,        
      },
      rules: {
        company_id:[{ required: true, message: " ", trigger: "blur" }],   
        device_id:[{ required: true, message: " ", trigger: "blur" }],     
        name: [{ required: true, message: " ", trigger: "blur" }],  
        region_id: [{ required: true, message: " ", trigger: "blur" }], 
        room_id : [{ required: true, message: " ", trigger: "blur" }],      
        orders: [{ required: true, message: " ", trigger: "blur" }],  
      },
      sceneForm:{
        company_id:0,
        room_id:0,
        deviceType:"",
        deviceId:"",
        status:"",
      },
      lightForm:{
        company_id:0,
        room_id:0,
        deviceType:"",
        deviceId:"",
        status:"",
      }
    };
  },
  created() {
    this.getRegion();    
    this.getData();    
  },
  methods: {  
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }, 
    handlerRowKeys(row){     
      return row.client_id;
    },     
    getRegion() {
      fetchGet("/api/device/region/list", { }).then((res) => {        
        if(res.status){
          this.regions = res.data;             
        }       
      });
    },
    getData() {     
      fetchGet("/api/device/device/list",  this.query).then((res) => {        
        if(res.status){
            this.tableData = res.data;
            this.pageTotal = res.count;
        }else{
          this.$message.success(res.msg);
        }
      });

      fetchGet("/api/base/version",  this.query).then((res) => {        
        if(res.status){
            this.vform = res.data;           
        }else{
          this.$message.success(res.msg);
        }
      });
    }, 
    handleSearch() {
      this.getData();
    },
    handleVersion(){
      this.versionVisible=true;
    },    
    saveVersionEdit(uform) {        
      this.$refs[uform].validate((valid) => {
        if (valid) {          
          fetchPost("/api/base/version/edit", this.vform).then((res) => {
              if (res.status) {
                this.versionVisible = false;               
              }
              this.$message.success(res.msg);
            }).catch((err) => {              
              this.$message.success(`出错了`);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 
    handleExpandChange(row, expandedRows) {   
      
    },
    handleStatusDel(parent,row){
      //console.log(row);
      //console.log(parent.client_id);
      //console.log(row.deviceType)
      //console.log(row.deviceId)
      
      fetchPost("/api/device/device/status/del",{"client_id":parent.client_id,"device_type":row.deviceType,"device_id":row.deviceId}).then((res) => {
        if (res.status) {
          this.getData();           
        }
        this.$message.success(res.msg);
      }).catch((err) => {              
        this.$message.success(`出错了`);
      });

    },
    handleCreate(index, row) {     
      this.getRegion();
      this.title = "新增设备";
      this.editVisible = true;
      this.uform = {
        id: 0,
        name: "",   
        company_id:this.query.company_id,    
        orders: 1,        
      };
    },
    setSchema(index, row){
      this.idx = index;      
      this.uform = row;
      this.editVisible = true;
      this.title = "模式设置";
    },
    handleEdit(index,row){
      this.getRegion();
      this.title = "新增编辑";
      this.editVisible = true;
      this.uform = row;
    },
    saveEdit(uform) {        
      this.$refs[uform].validate((valid) => {
        if (valid) {          
          fetchPost("/api/device/device/edit", this.uform).then((res) => {
              if (res.status) {
                this.editVisible = false;
                //this.$set(this.tableData, this.idx, this.uform);
                this.getData();

                this.refresh(); 
              }
              this.$message.success(res.msg);
            }).catch((err) => {              
              this.$message.success(`出错了`);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 
    handleDevice(index, row){
     this.$confirm("确定要获取设备信息吗？", "提示", {
        type: "warning",
      }).then(() => {
          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/STATUS/"+this.companyId+"/"+row.room_id,deviceType:"",deviceId:0}).then((res) => {            
              if (res.status) {
                this.$message.success(`指令已发送`);
              }else{
                this.$message.error(res.msg);
              }
          }).catch((err) => {              
              this.$message.success(`出错了`);
          });
      });
    },
    handleUpgrade(index, row){
     this.$confirm("确定要升级吗？", "提示", {
        type: "warning",
      }).then(() => {
          fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/UPGRADE/"+this.companyId+"/"+row.room_id}).then((res) => {            
              if (res.status) {
                this.$message.success(`指令已发送`);
              }else{
                this.$message.error(res.msg);
              }
          }).catch((err) => {              
              this.$message.success(`出错了`);
          });
      });
    },
    handleReboot(){
      this.rebootVisible=true;
    },
    saveRebootEdit(bform){      
      fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/REBOOT/"+this.companyId,"time":this.bform.time}).then((res) => {            
            if (res.status) {
              this.$message.success(`指令已发送`);
              this.rebootVisible=false;
            }else{
              this.$message.error(res.msg);
            }
        }).catch((err) => {              
            this.$message.success(`出错了`);
        });
    },
    handleSetting(){
      this.settingVisible=true;
    },
    changeSettingEdit(settingForm){
      if(this.multipleSelection.length==0){
        this.$message.error("选择设置对象");
        return;      
      }

      this.$confirm("确定要设置吗？", "提示", {
        type: "warning",
      }).then(() => {
        this.multipleSelection.forEach((e)=>{
             this.settingForm.topic="SMARTLIGHTING/SERVER/SETTING/"+this.companyId+"/"+e.room_id;
             fetchPost("/api/command", this.settingForm).then((res) => {            
                if (res.status) {
                  
                }else{
                  this.$message.error(res.msg);
                }
            }).catch((err) => {              
                this.$message.success(`出错了`);
            });
        });

        this.settingVisible=false;
        this.$message.success("指令已发送");
      });
    },
    refresh(){
      fetchGet("/api/region/menu", {}).then((res) => {         
        if(res.status){              
          this.$store.commit("changeMenu", res.data);     
        }else{
          this.$message.success(res.msg);
        }
      });

      fetchGet("/api/region/batch", {kind:1}).then((res) => {    
        if(res.status){     
          this.$store.commit("changeLightRoomList", res.data);          
        }else{
          this.$message.success(res.msg);
        }
      });

      fetchGet("/api/region/batch", {kind:2}).then((res) => {   
        if(res.status){            
          this.$store.commit("changeDisinfectRoomList", res.data);      
        }else{
          this.$message.success(res.msg);
        }
      });
    },   
    handlePageChange(val) {
      this.$set(this.query, 'page_index', val);
      this.getData();
    },
  },
};
</script>

<style scoped>

#container{
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.box-card{
 padding-top: 15px;
}
.show-ellipsis {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.crumbs {
  padding-bottom: 5px;
}
.handle-box {
  margin-bottom: 15px;
  margin-top: -15px;
}

.handle-select {
  width: 150px;
}
.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>

<style>
.el-table__expanded-cell[class*=cell]{
  padding: 10px 10px;
}
</style>