<template>
     <div id="container">    
     <el-card class="box-card">
        <div class="container">
            <div class="handle-box">
                <!--el-button
                    type="danger"
                    icon="el-icon-plus"
                    class="handle-del mr10"
                    @click="handleCreate"
                >新增定时</el-button-->                      
                <el-input v-model="query.title" placeholder="关键词" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="name" label="标题" align="center"></el-table-column>                
                <el-table-column prop="start_time" label="开始时间" align="center"></el-table-column>
                <el-table-column prop="weeks" label="周几" align="center"></el-table-column>  
                <el-table-column prop="duration" label="时长" align="center"></el-table-column>                
                <el-table-column prop="status" label="状态" align="center">
                    <template slot-scope="scope">
                       <el-tag type="success" @click="handleChange(scope.$index, scope.row)" v-show="(scope.row.status==0)">关</el-tag>
                       <el-tag type="danger" @click="handleChange(scope.$index, scope.row)" v-show="(scope.row.status==1)">开</el-tag>
                    </template>
                </el-table-column> 
                <el-table-column prop="entry_id_start" label="任务ID" align="center"></el-table-column>
                <el-table-column prop="entry_id_stop" label="任务ID" align="center"></el-table-column>
                <el-table-column label="录入时间" align="center" :show-overflow-tooltip="true">
                    <template slot-scope="scope">{{ scope.row.created|dateformat('YYYY-MM-DD HH:mm:ss')}}</template>
                </el-table-column>

                <el-table-column label="操作" width="120"  align="center">
                    <template slot-scope="scope">
                        <!--el-button
                        size="small"
                            type="primary"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                        >编辑</el-button-->
                        <el-button
                        size="small"
                            type="danger"
                            icon="el-icon-delete"
                            @click="handleDelete(scope.$index, scope.row)"
                        >删除</el-button>                                           
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination" style="margin-top:20px">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page_index"
                    :page-size="query.page_size"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>
     </el-card>
        <!-- 编辑弹出框 -->
        <el-dialog :title="title" :visible.sync="editVisible" width="50%">
            <el-form ref="uform" :rules="rules" :model="uform" label-width="120px">               
                <el-form-item prop="id" label="编号" v-show="false">
                    <el-input v-model="uform.id"></el-input>
                </el-form-item>
                <el-form-item prop="name" label="标题">
                    <el-input v-model="uform.name"></el-input>
                </el-form-item> 
                <el-form-item prop="simple" label="简易版">
                    <el-select v-model="uform.simple" placeholder="是否简易版" class="handle-select mr10">
                        <el-option key="s0" label="是" :value="1"></el-option>
                        <el-option key="s1" label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="regin_id" label="区域">
                <el-cascader
                    size="small"
                    :options="regions"   
                    :show-all-levels="true"
                    :props="{value:'id',label:'name',children:'children',checkStrictly: true, emitPath:false}"
                    v-model="uform.region_id"            
                    placeholder="请选择区域"
                    class="handle-del mr10"            
                ></el-cascader>  
                </el-form-item>             
                <el-form-item prop="start_month" label="开始月份">                    
                    <el-date-picker
                        v-model="uform.start_month"
                        type="month"
                        format="MM月"
                        value-format="M"
                        placeholder="选择月份">
                    </el-date-picker>
                </el-form-item>                
                <el-form-item prop="end_month" label="结束月份">
                    <el-date-picker
                        v-model="uform.end_month"
                        type="month"
                        format="MM月"
                        value-format="M"
                        placeholder="选择月份">
                    </el-date-picker>
                </el-form-item>   
                <el-form-item prop="weeks" label="重复频率">
                <el-select v-model="uform.weeks" multiple  placeholder="选择周几" class="handle-select mr10">                    
                    <el-option key="s1" label="周一" :value="1"></el-option>     
                    <el-option key="s2" label="周二" :value="2"></el-option>
                    <el-option key="s3" label="周三" :value="3"></el-option>
                    <el-option key="s4" label="周四" :value="4"></el-option>
                    <el-option key="s5" label="周五" :value="5"></el-option>
                    <el-option key="s6" label="周六" :value="6"></el-option>   
                    <el-option key="s7" label="周日" :value="0"></el-option>            
                </el-select>
                </el-form-item>
                <el-form-item prop="start_time" label="开始时间">                    
                    <el-time-select
                        v-model="uform.start_time"
                        :picker-options="{
                            start: '06:30',
                            step: '00:15',
                            end: '23:30',  
                        }"              
                        placeholder="选择时间">
                        </el-time-select>
                </el-form-item>
                <el-form-item prop="duration" label="时长">
                    <el-input v-model="uform.duration"></el-input>
                </el-form-item>                                  
                <el-form-item prop="speed" label="转速">
                    <el-input v-model="uform.speed"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit('uform')">确 定</el-button>
            </span>
        </el-dialog>

      
    </div>
</template>

<script>

import { fetchPost, fetchGet, fetchDelete } from "@/service/index";


export default {
    name: 'basetable',
    data() {
        return {
            query: { 
                title: '',
                page_index: 1,
                page_size: 8
            },    
            domains:[],   
            subjects: [],       
            groups: [],
            regions:[],           
            title: '编辑',           
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,          
            pageTotal: 0,
            uform: {
                id: 0,                
                name: '',
                simple:1,
                regin_id:"",
                start_month: '',
                end_month: '',    
                weeks:"", 
                start_time:'', 
                speed:300,
                duration:20,
            },    
            ossurl: '',
            idx: -1,
            id: -1,
            rules: {
                id: [{ type: 'number', min: 0, required: true, message: '', trigger: 'blur' }],
                simple: [{ required: true, message: ' ', trigger: 'change' }],
                start_month: [{ required: true, message: ' ', trigger: 'change' }],
                end_month: [{ required: true, message: ' ', trigger: 'blur' }],
                weeks: [{required: true, message: ' ', trigger: 'blur' }],
                start_time: [{required: true, message: ' ', trigger: 'blur' }],
                duration: [{required: true, message: ' ', trigger: 'blur' }],
                speed: [{required: true, message: ' ', trigger: 'blur' }],
            },              
        };
    },
    created() {             
        this.getData();    
    },    
    methods: {
        getData() {  
            fetchGet('/api/device/timing/list', this.query).then(res => {
                this.tableData = res.data;
                this.pageTotal = res.count;
            });
        },
        handleSearch() {     
            console.log(this.query)      
            this.getData();
        },
        handleDelete(index, row) {
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            }).then(() => {
                fetchGet('/api/device/timing/del/' + row.id).then(res => {
                    if (res.status) {
                        this.$message.success('删除成功');
                        this.tableData.splice(index, 1);
                        this.getData();   
                    } else {
                        this.$message.success(res.msg);
                    }
                });
            }).catch(() => {});
        },  
        handleChange(index, row) {
            this.$confirm('确定要更改状态吗？', '提示', {
                type: 'warning'
            }).then(() => {
                fetchPost('/api/device/timing/change',{id:row.id}).then(res => {
                    if (res.status) {
                        this.$message.success('操作成功');
                        this.getData();
                    } else {
                        this.$message.success(res.msg);
                    }
                });
           }).catch(() => {});
        },    
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {          
            this.$message.error(`禁止批量删除！`);
        },       
        handleCreate() {
            if(this.query.company_id<=0){               
                this.$message.success('选择单位');
                return;
            }

            this.getRegion();

            this.title = '新增';
            this.editVisible = true;           
            this.uform={
                id: 0,                
                name: '定时任务',               
                start_time:'',
                speed:300,
                duration:20,   
                weeks:"",  
            };
        },        
        handleEdit(index, row) { 
            this.query.company_id=row.company_id; 
            this.getRegion();

            this.idx = index;
            this.uform = row;           
            if(!Array.isArray(this.uform.weeks)){
                this.uform.weeks= row.weeks.split(',').map(i => parseInt(i, 0));
            }
            this.editVisible = true;
            this.title = '编辑';             
        },             
        saveEdit(uform) {            
            this.$refs[uform].validate(valid => {  
                if(this.uform.start_month>this.uform.end_month){
                    this.$message.success(`开始月份要小于等于结束月份`);
                    return;
                }               
                if (valid) {
                    this.uform.weeks=this.uform.weeks.toString();                    
                    fetchPost('/api/device/timing/edit', this.uform)
                        .then(res => {
                            if (res.status) {
                                this.editVisible = false;
                                this.$set(this.tableData, this.idx, this.uform);
                                this.getData();
                            }
                            this.$message.success(res.msg);
                        })
                        .catch(err => {
                            this.$message.success(`出错了`);
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleContent(index, row) {                     
            this.uform = row;  
            this.contentVisible = true;
            this.title = '编辑';                
        },            
        handlePageChange(val) {
            this.$set(this.query, 'page_index', val);
            this.getData();
        },        
    }
};
</script>

<style scoped>
#container{
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.box-card{
 padding-top: 15px;
}

.crumbs {
    padding-bottom: 5px;
}
.handle-box {
    margin-bottom: 15px;
    margin-top: -15px;
}

.handle-select_top {
    width: 150px;
}

.handle-select {
    width: 360px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.editor-btn {
    margin-top: 20px;
}
</style>
