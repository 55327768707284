<template>
  <div id="LightB">
    <div class="manageBox">
      <h4 class="tit">
        <span>批量管理</span>
        <div class="lightStatusTip">
          窗帘状态：<span>打开</span> <span>关闭</span>
        </div>

        <!--div class="searchBar">
          <div class="searchItem">
            <span>定时日期设定：</span>
            <div class="item" style="width:164px">
              <el-select v-model="timeForm.weekList" multiple collapse-tags placeholder="请选择">
                <el-option
                  v-for="item in weeks"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchItem">
            <span>时长设定：</span>
            <div class="item" style="width:164px">
              <el-select v-model="timeForm.duration" placeholder="请选择">
                <el-option
                  v-for="item in long"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>   
          <div class="searchItem">
            <span>时间设定：</span>
            <div class="item" style="width:204px">
              <el-time-select 
                v-model="timeForm.start_time"                
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '24:00'
                }"
                placeholder="选择时间">
              </el-time-select>
            </div>
          </div>
                
          <div id="submitBtn" class="noselect submitBtn" @click="setTiming()">
            确定
          </div>
        </div-->
      </h4>
      <!-- <div class="lightList">
        <div class="row">
          <div class="rowName">
            <img src="../../assets/images/check.png" alt="" />
            <span>一年级</span>
          </div>
          <div class="rowBox red">
            <img src="../../assets/images/check1.png" alt="" />
            <span>一年一班</span>
          </div>
          <div class="rowBox green">
            <img src="../../assets/images/check.png" alt="" />
            <span>一年一班</span>
          </div>
        </div>
        <div class="row"></div>
        <div class="row"></div>
      </div> -->
      <div class="lightList">
        <div class="row" v-for="(item, index) in this.$store.state.curtainRoomList" :key="index">
          <div
            class="rowName"
            :class="[
              { line2: item.children?.length > 10 },
              { line3: item.children?.length > 20 },
            ]"
          >
            <img
              src="../../assets/images/check1.png"
              v-show="item.check"
              @click="roomCheck1(item.check, index)"
              alt=""
            />
            <img
              src="../../assets/images/check.png"
              v-show="!item.check"
              @click="roomCheck1(item.check, index)"
              alt=""
            />
            <span>{{ item.roomName }}</span>
          </div>
          <div class="rowBoxList">
            <div
              class="rowBox"
              v-for="(item1, index1) in item.children"
              :key="index1"
              :class="[
                { red: !item1.isOpen },
                { green: item1.isOpen },
                ,
                { hasVal: item1.roomName },
              ]"
            >
              <img
                src="../../assets/images/check1.png"
                v-show="item1.check"
                alt=""
                v-if="item1.roomName"
                @click="roomCheck2(item1.check, index, index1)"
              />
              <img
                src="../../assets/images/check.png"
                v-show="!item1.check"
                alt=""
                v-if="item1.roomName"
                @click="roomCheck2(item1.check, index, index1)"
              />
              <span>{{ item1.roomName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fenye">
        <!--el-pagination background layout="prev, pager, next" :total="50">
        </el-pagination-->
        <div class="btnGroup">
          <div class="open" @click="openLight()">打开</div>
          <div class="close" @click="closeLight()">关闭</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchPost, fetchGet, fetchDelete, hasAction } from "@/service/index";
import { Console } from "console";

export default {
  name: "curtainB",

  data() {
    return {
      companyId: localStorage.getItem('company_id'),
      curtainRoomList: this.$store.state.curtainRoomList,
      timeForm:{
        weekList:[],
        weeks:"",
        start_time:"18:00",
        duration:60,
        speed:0,
        room_nos:"",
        simple:1,
        name:"灯光定时开关任务"
      },
      long: [
        {
          value: 60,
          label: "1小时",
        },
        {
          value: 120,
          label: "2小时",
        },
        {
          value: 180,
          label: "3小时",
        },
        {
          value: 240,
          label: "4小时",
        },
        {
          value: 300,
          label: "5小时",
        },
        {
          value: 360,
          label: "6小时",
        },
        {
          value: 420,
          label: "7小时",
        },
        {
          value: 480,
          label: "8小时",
        },
        {
          value: 540,
          label: "9小时",
        },
        {
          value: 600,
          label: "10小时",
        },
        {
          value: 660,
          label: "11小时",
        },
        {
          value: 720,
          label: "12小时",
        },
      ],    
      weeks: [
        {
          value: "1",
          label: "周一",
        },
        {
          value: "2",
          label: "周二",
        },
        {
          value: "3",
          label: "周三",
        },
        {
          value: "4",
          label: "周四",
        },
        {
          value: "5",
          label: "周五",
        },
        {
          value: "6",
          label: "周六",
        },
        {
          value: "0",
          label: "周日",
        },
      ],
    };
  },

  mounted() {},
  created() {},
  methods: {   
    roomCheck1(val, idx) {
      this.curtainRoomList[idx].check = !val;
      for (let i = 0; i < this.curtainRoomList[idx].children?.length; i++) {
        this.curtainRoomList[idx].children[i].check = !val;
      }

      this.$store.commit("changeCurtainRoomList", this.curtainRoomList); 
    },
    roomCheck2(val, idx, idx1) {
      // console.log(val,idx,idx1)
      this.curtainRoomList[idx].children[idx1].check = !val;

      this.$store.commit("changeCurtainRoomList", this.curtainRoomList);
    },
    openLight() {
      // 遍历数据选中为true的值，批量打开
      console.log("打开");

      var ids="";

      this.$store.state.curtainRoomList.forEach(e=>{
        e.children?.forEach(c=>{   
          if(c.check){
            ids=ids+","+c.clientId;
            fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+c.roomId,deviceType:"curtain",deviceId:0,"status":1}).then((data) => {           
                if (data.status) {
                            
                } else {               
                  this.$message.error(data.msg);               
                }
              }).catch((err) => {
                console.log(err);
                this.$message.error(err);              
              });   
          }
        });
      });

      this.$store.commit("setBatCurtainOpen", {clientIds:ids,isOpen:true}); 
      
      if(ids.length>0){
        this.$message.success("发送打开指令");
      }else{
        this.$message.success("选择操作对象");
      }
    },
    closeLight() {
      console.log("关闭");      

      var ids="";

      this.$store.state.curtainRoomList.forEach(e=>{
        e.children?.forEach(c=>{  
          if(c.check){  
            ids=ids+","+c.clientId;
            fetchPost("/api/command", {"topic":"SMARTLIGHTING/SERVER/COMMAND/"+this.companyId+"/"+c.roomId,deviceType:"curtain",deviceId:0,"status":0}).then((data) => {           
              if (data.status) {
                          
              } else {               
                this.$message.error(data.msg);               
              }
            }).catch((err) => {
              console.log(err);
              this.$message.error(err);              
            });   
          }
        });
      });

      this.$store.commit("setBatCurtainOpen", {clientIds:ids,isOpen:false}); 
      
      if(ids.length>0){
        this.$message.success("发送关闭指令");
      }else{
        this.$message.success("选择操作对象");
      }
    },
    setTiming(){
      if(this.timeForm.weekList.length==0){
        this.$message.error("请选择日期");
        return;
      }     

      this.timeForm.room_nos="";

      this.curtainRoomList.forEach(e=>{
        e.children?.forEach(c=>{
          if(c.check){
            if(this.timeForm.room_nos.length==0){
              this.timeForm.room_nos=c.roomId;
            }else{
              this.timeForm.room_nos=this.timeForm.room_nos+","+c.roomId;
            }
          }
        });
      });

      if(this.timeForm.room_nos.length==0){
        this.$message.error("请选择定时对象");
        return;
      }

      this.timeForm.weeks=this.timeForm.weekList.toString();
        fetchPost("/api/device/timing/edit",this.timeForm).then((res) => {   
          if(res.status){
            this.$message.success("定时完成 请启动定时器");
          }else{
            this.$message.error(res.msg);
          }
      });
    }
  },
};
</script>

<style lang="css" scoped>
#LightB {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px 0 25px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.manageBox {
  width: 100%;
  /* min-height: 200px; */
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10px 30px;
}
h4.tit {
  font-size: 22px;
  line-height: 62px;
  height: 62px;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
}
h4.tit .lightStatusTip {
  font-size: 16px;
  color: #464852;
}
h4.tit .lightStatusTip span {
  position: relative;
  padding-left: 38px;
  /* display: inline-block; */
}
h4.tit .lightStatusTip span::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  width: 10px;
  height: 10px;
  left: 12px;
  top: 6px;
}
h4.tit .lightStatusTip span:first-child::before {
  background-color: #03b86d;
}
h4.tit .lightStatusTip span:nth-child(2)::before {
  background-color: #ff3f3f;
}

h4.tit .searchBar {
  display: flex;
  font-size: 16px;
  font-weight: normal;
  color: #464852;
  font-size: 14px;
}

.searchItem {
  display: flex;
  margin-right: 30px;
}


h4.tit .submitBtn {
  width: 86px;
  height: 35px;
  background: #1C7CF7;
  border-radius: 5px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  transition: all .3s;
  margin-top: 14px;
}
h4.tit .submitBtn:hover {
  background: #0e61ce;
  cursor: pointer;
}

.lightList {
  width: 100%;
  /* height: 350px; */
  border: 1px solid #a7b2cc;
  border-left: 3px solid #2761fc;
}
.row {
  border-bottom: 1px solid #eaedf2;
  min-height: 52px;
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
}
.row:nth-child(2n-1) {
  background-color: #f3f5fa;
}
.row > div {
  border-right: 1px solid #dadfea;
  line-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  font-size: 16px;
}
.row img {
  margin-right: 6px;
  vertical-align: -3px;
  cursor: pointer;
}
.row > div.rowName {
  width: 118px;
  /* height: 52px; */
  color: #3c3f4d;
  font-weight: 700;
}
.row > div.rowName.line2 {
  line-height: 104px;
}
.row > div.rowName.line3 {
  line-height: 156px;
}
.rowBoxList {
  width: calc(100% - 118px);
  display: flex;
  flex-wrap: wrap;
}
.row .rowBox {
  width: 10%;
  height: 52px;
  color: #464852;
  border-right: 1px solid #dadfea;
  line-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 18px;
  font-size: 16px;
}
.row .rowBox {
  position: relative;
}
.row .rowBox.hasVal::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  right: 10px;
  top: 22px;
}
.row .rowBox.red::after {
  background-color: #ff3f3f;
}
.row .rowBox.green::after {
  background-color: #03b86d;
}
.fenye {
  position: relative;
  height: 70px;
  padding: 14px 0;
}
.btnGroup {
  position: absolute;
  right: 0;
  top: 14px;
  display: flex;
}
.btnGroup > div {
  width: 134px;
  height: 42px;
  margin-left: 12px;

  border-radius: 21px;
  text-align: center;
  line-height: 42px;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  transition: all .3s;
}
.btnGroup > div.open {
  background: linear-gradient(180deg, #509cff 0%, #2863fc 100%);
}
.btnGroup > div.open:hover{
  box-shadow: 0px 4px 10px 0px rgba(70, 118, 240,0.5);
}
.btnGroup > div.close:hover{
  box-shadow: 0px 4px 10px 0px rgba(255, 63, 63,.5);
}
.btnGroup > div.close {
  background: linear-gradient(0deg, #ff3f3f 0%, #ff9e78 100%);
}
.fenye /deep/.el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: transparent;
  color: #5b687f;
  border: 1px solid #e2e2e2;
  border-radius: 0px;
  width: 55px;
  height: 36px;
  line-height: 36px;
  margin: 0;
  border-right: 0px;
}
.fenye /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1471e8;
  color: #ffffff;
}
.fenye /deep/.el-pagination.is-background .btn-next {
  background-color: transparent;
  margin: 0;
  color: #5b687f;
  min-width: 58px;
  height: 36px;
  border-radius: 0px;
  border: 1px solid #e2e2e2;
}
.fenye /deep/.el-pagination.is-background .btn-prev {
  background-color: transparent;
  margin: 0;
  color: #5b687f;
  min-width: 58px;
  height: 36px;
  border-radius: 0px;
  border: 1px solid #e2e2e2;
  border-right: 0px;
}
@media screen and (max-width:1900px){
h4.tit {
  font-size: 18px;
}
h4.tit .lightStatusTip {
  font-size: 14px;
}


.row > div {
  font-size: 14px;
}
.row .rowBox {
  font-size: 12px;
}
.lightList {
  /* min-width: 1300px; */
}
}
</style>